import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  roles: [],
  permissionsByRole: {},
  allPermissions: [],
  rolesByUserId: {},
  rolesByGroupId: {},
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchRoles: async ({ commit }) => {
    try {
      const roles = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: "role?limit=1000&orderBy=name",
        })
      );
      if (roles)
        commit("SET", {
          property: "roles",
          payload: roles,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createRole: async ({ commit }, payload) => {
    try {
      const newRole = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: "role",
        }),
        payload
      );
      if (newRole)
        commit("ADD_TO_ARRAY", {
          property: "roles",
          payload: newRole,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateRole: async ({ commit }, payload) => {
    try {
      const updatedRole = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `role/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedRole)
        commit("UPDATE_ARRAY", {
          property: "roles",
          idProp: "minervaId",
          payload: updatedRole,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteRole: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `role/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "roles",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchPermissionsByRole: async ({ commit }, { roleName }) => {
    try {
      if (!roleName) {
        throw new Error("RoleName must be provided");
      }
      const permissions = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `rolepermission?limit=1000&orderBy=permission_name&role_name=${roleName}`,
        })
      );
      if (permissions)
        commit("SET_ARRAY_BY_ID", {
          property: "permissionsByRole",
          id: roleName,
          payload: permissions,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addPermissionsByRole: async ({ commit }, { roleName, payload }) => {
    try {
      const newPermissionsByRole = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: "rolepermission",
        }),
        payload
      );
      if (newPermissionsByRole)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "permissionsByRole",
          id: roleName,
          payload: newPermissionsByRole,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deletePermissionsByRole: async ({ commit }, { roleName, payload }) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `rolepermission/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "permissionsByRole",
          id: roleName,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchAllPermissions: async ({ commit }) => {
    try {
      const allPermissions = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: "rolepermission?limit=1000&orderBy=permission_name",
        })
      );
      if (allPermissions)
        commit("SET", {
          property: "allPermissions",
          payload: allPermissions,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchRolesByUser: async ({ commit }, user) => {
    try {
      const roles = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `minervauserrole?limit=1000&orderBy=role_name&minervauser_email=${encodeURIComponent(
            user.email
          )}`,
        })
      );
      if (roles)
        commit("SET_ARRAY_BY_ID", {
          property: "rolesByUserId",
          id: user.minervaId,
          payload: roles,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addRoleByUser: async ({ commit }, { user, payload }) => {
    try {
      const newRoleByUser = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: "minervauserrole",
        }),
        payload
      );
      if (newRoleByUser)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "rolesByUserId",
          id: user.minervaId,
          payload: newRoleByUser,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteRoleByUser: async ({ commit }, { user, payload }) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `minervauserrole/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "rolesByUserId",
          id: user.minervaId,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchRolesByGroup: async ({ commit }, group) => {
    try {
      const roles = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `grouprole?limit=1000&orderBy=role_name&group_name=${group.name}`,
        })
      );
      if (roles)
        commit("SET_ARRAY_BY_ID", {
          property: "rolesByGroupId",
          id: group.minervaId,
          payload: roles,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addRoleByGroup: async ({ commit }, { group, payload }) => {
    try {
      const newRoleByGroup = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: "grouprole",
        }),
        payload
      );
      if (newRoleByGroup)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "rolesByGroupId",
          id: group.minervaId,
          payload: newRoleByGroup,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteRoleByGroup: async ({ commit }, { group, payload }) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `grouprole/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "rolesByGroupId",
          id: group.minervaId,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
