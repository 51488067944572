import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  permittedPharmacies: [],
  pharmacyOrders: [],
  pharmacyOrdersWeekly: {},
  datesFetched: {},
});

const mutations = {
  ...vuexMutations,
  UPDATE_PHARMACY_ORDER: (state, { pharmacyId, pharmacyOrder }) => {
    const orderIndex = state.pharmacyOrders.findIndex(
      (order) => order.order_data.order_id === pharmacyOrder.order_data.order_id
    );

    orderIndex !== undefined
      ? state.pharmacyOrders.splice(orderIndex, 1, pharmacyOrder)
      : state.pharmacyOrders.push(pharmacyOrder);

    const hasState = state.pharmacyOrdersWeekly[pharmacyId];
    if (!hasState) return;
    const orderIndexWeekly = state.pharmacyOrdersWeekly[pharmacyId].findIndex(
      (order) => order.order_data.order_id === pharmacyOrder.order_data.order_id
    );

    orderIndexWeekly !== undefined
      ? state.pharmacyOrdersWeekly[pharmacyId].splice(
          orderIndex,
          1,
          pharmacyOrder
        )
      : state.pharmacyOrdersWeekly[pharmacyId].push(pharmacyOrder);
  },
  DELETE_PHARMACY_ORDER: (state, { pharmacyId, pharmacyOrder }) => {
    const orderIndex = state.pharmacyOrders.findIndex(
      (order) => order.order_data.order_id === pharmacyOrder.order_data.order_id
    );

    if (orderIndex) state.pharmacyOrders.splice(orderIndex, 1);

    const hasState = state.pharmacyOrdersWeekly[pharmacyId];
    if (!hasState) return;
    const orderIndexWeekly = state.pharmacyOrdersWeekly[pharmacyId].findIndex(
      (order) => order.order_data.order_id === pharmacyOrder.order_data.order_id
    );
    if (orderIndexWeekly)
      state.pharmacyOrdersWeekly[pharmacyId].splice(orderIndex, 1);
  },
};

const actions = {
  fetchPermittedPharmacies: async ({ commit }) => {
    try {
      const permittedPharmacies = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "viewHelperApi",
          url: `permitted_pharmacies`,
        })
      );

      if (permittedPharmacies)
        commit("SET", {
          property: "permittedPharmacies",
          payload: permittedPharmacies,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchPharmacyOrders: async ({ commit }, { pharmacyId, offset = 0 }) => {
    try {
      const pharmacyOrders = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyManagerApi",
          url: `pharmacy/${pharmacyId}/pharmacy_order?offset=${offset}&limit=20`,
        })
      );
      if (pharmacyOrders)
        if (offset === 0) {
          commit("SET", {
            property: "pharmacyOrders",
            payload: pharmacyOrders,
          });
        } else {
          commit("ADD_TO_ARRAY", {
            property: "pharmacyOrders",
            payload: pharmacyOrders,
          });
        }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchPharmacyOrdersWeekly: async (
    { commit },
    { pharmacyId, startDate, endDate }
  ) => {
    // if (
    //   state.datesFetched[pharmacyId] &&
    //   state.datesFetched[pharmacyId].includes(startDate)
    // ) {
    //   return;
    // }
    try {
      const pharmacyOrders = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyManagerApi",
          url: `pharmacy/${pharmacyId}/pharmacy_order?date_filter=true&start_date=${startDate}&end_date=${endDate}`,
        })
      );
      if (pharmacyOrders) {
        // commit("ADD_TO_ARRAY_BY_ID", {
        //   property: "datesFetched",
        //   id: pharmacyId,
        //   payload: startDate,
        // });
        commit("SET_ARRAY_BY_ID", {
          property: "pharmacyOrdersWeekly",
          id: pharmacyId,
          payload: pharmacyOrders,
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchPharmacyOrdersOnAction: async ({ commit }, { pharmacyId, length }) => {
    if (!length) {
      throw new Error("length is required");
    }
    try {
      const pharmacyOrders = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyManagerApi",
          url: `pharmacy/${pharmacyId}/pharmacy_order?limit=${length}`,
        })
      );
      if (pharmacyOrders)
        commit("SET", {
          property: "pharmacyOrders",
          payload: pharmacyOrders,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchAllPharmacyOrders: async ({ commit }, { offset = 0 }) => {
    try {
      const pharmacyOrders = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyManagerApi",
          url: `pharmacy_order?offset=${offset}&limit=20`,
        })
      );
      if (pharmacyOrders) {
        if (offset === 0) {
          commit("SET", {
            property: "pharmacyOrders",
            payload: pharmacyOrders,
          });
        } else {
          commit("ADD_TO_ARRAY", {
            property: "pharmacyOrders",
            payload: pharmacyOrders,
          });
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchAllPharmacyOrdersOnAction: async ({ commit }, { length }) => {
    if (!length) {
      throw new Error("length is required");
    }
    try {
      const pharmacyOrders = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyManagerApi",
          url: `pharmacy_order?limit=${length}`,
        })
      );
      if (pharmacyOrders) {
        commit("SET", {
          property: "pharmacyOrders",
          payload: pharmacyOrders,
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchPharmacyOrder: async ({ commit }, { pharmacyId, pharmacyOrderId }) => {
    try {
      const pharmacyOrder = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyManagerApi",
          url: `pharmacy/${pharmacyId}/pharmacy_order/${pharmacyOrderId}`,
        })
      );
      if (pharmacyOrder)
        commit("UPDATE_PHARMACY_ORDER", { pharmacyId, pharmacyOrder });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  acceptPharmacyOrder: async ({ dispatch }, { pharmacyOrder, payload }) => {
    const pharmacyOrderId = pharmacyOrder.order_data.order_id;
    const pharmacyId = pharmacyOrder.active_pharmacy.minervaId;
    try {
      const success = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "pharmacyManagerApi",
          url: `pharmacy/${pharmacyId}/pharmacy_order/${pharmacyOrderId}/accept`,
        }),
        payload
      );
      if (success)
        dispatch("fetchPharmacyOrder", { pharmacyId, pharmacyOrderId });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  suggestNewOrderDateTime: async ({ dispatch }, { pharmacyOrder, payload }) => {
    const pharmacyOrderId = pharmacyOrder.order_data.order_id;
    const pharmacyId = pharmacyOrder.active_pharmacy.minervaId;
    try {
      const success = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "pharmacyManagerApi",
          url: `pharmacy/${pharmacyId}/pharmacy_order/${pharmacyOrderId}/suggest_modify`,
        }),
        payload
      );
      if (success)
        dispatch("fetchPharmacyOrder", { pharmacyId, pharmacyOrderId });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  rejectPharmacyOrder: async ({ dispatch }, { pharmacyOrder, payload }) => {
    const pharmacyOrderId = pharmacyOrder.order_data.order_id;
    const pharmacyId = pharmacyOrder.active_pharmacy.minervaId;
    try {
      const success = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "pharmacyManagerApi",
          url: `pharmacy/${pharmacyId}/pharmacy_order/${pharmacyOrderId}/cancel`,
        }),
        payload
      );
      if (success)
        dispatch("fetchPharmacyOrder", { pharmacyId, pharmacyOrderId });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  reroutePharmacyOrder: async ({ commit }, { pharmacyOrder, payload }) => {
    const pharmacyOrderId = pharmacyOrder.order_data.order_id;
    try {
      const success = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "pharmacyManagerApi",
          url: `pharmacy_order/${pharmacyOrderId}/reroute_order`,
        }),
        payload
      );
      if (success)
        commit("DELETE_PHARMACY_ORDER", {
          pharmacyId: pharmacyOrder.active_pharmacy.minervaId,
          pharmacyOrder,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  setWaybill: async ({ dispatch }, { pharmacyOrder, newWaybill }) => {
    const pharmacyOrderId = pharmacyOrder.order_data.order_id;
    const pharmacyId = pharmacyOrder.active_pharmacy.minervaId;
    try {
      const success = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "pharmacyManagerApi",
          url: `pharmacy/${pharmacyId}/pharmacy_order/${pharmacyOrderId}/set_waybill`,
        }),
        newWaybill
      );
      if (success)
        dispatch("fetchPharmacyOrder", { pharmacyId, pharmacyOrderId });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
