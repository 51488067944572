import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  organizations: [],
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchOrganizations: async ({ commit }) => {
    try {
      const organizations = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "organizationServerApi",
          url: "organization",
        })
      );
      if (organizations)
        commit("SET", {
          property: "organizations",
          payload: organizations,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createOrganization: async ({ commit }, payload) => {
    try {
      const newOrganization = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "organizationServerApi",
          url: "organization",
        }),
        payload
      );
      if (newOrganization)
        commit("ADD_TO_ARRAY", {
          property: "organizations",
          payload: newOrganization,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateOrganization: async ({ commit }, payload) => {
    try {
      const updatedOrganization = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "organizationServerApi",
          url: `organization/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedOrganization)
        commit("UPDATE_ARRAY", {
          property: "organizations",
          idProp: "minervaId",
          payload: updatedOrganization,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteOrganization: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "organizationServerApi",
          url: `organization/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "organizations",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
