import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  permittedSites: [],
  studySites: {},
  studySiteOrders: {},
  studySiteSubjects: {},
  studySiteNetworks: {},
  studySiteProducts: {},
  productNetworkDetails: {},
  availableDates: {},
  orderReportToken: "",
  orderSummary: [],
  billingReports: {},
  subjectOtherOrders: {},
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchPermittedSites: async ({ commit }, { showInactive }) => {
    try {
      const permittedSites = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "viewHelperApi",
          url: `permitted_studysites?limit=1000&show_inactive=${showInactive}`,
        })
      );
      if (permittedSites)
        commit("SET", { property: "permittedSites", payload: permittedSites });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySite: async ({ commit }, { studyId, studySiteId }) => {
    try {
      const studySite = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}?limit=1000`,
        })
      );
      if (studySite)
        commit("SET_OBJECT_BY_IDPROP", {
          property: "studySites",
          idProp: "minervaId",
          payload: studySite,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchAllStudySiteOrders: async (
    { commit, dispatch },
    { studyId, studySiteId, length, shouldFetchBilling = false }
  ) => {
    if (!length) {
      throw new Error("length is required");
    }
    try {
      const studySiteOrders = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "doseOrderManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteorder?limit=${length}&orderBy=-created_at`,
        })
      );
      if (studySiteOrders) {
        commit("SET_ARRAY_BY_ID", {
          property: "studySiteOrders",
          id: studySiteId,
          payload: studySiteOrders,
        });
        if (shouldFetchBilling) {
          dispatch("fetchBillingReports", studySiteOrders);
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySiteOrders: async (
    { commit, dispatch },
    { studyId, studySiteId, offset = 0, shouldFetchBilling = false }
  ) => {
    try {
      const studySiteOrders = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "doseOrderManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteorder?offset=${offset}&limit=20&orderBy=-created_at`,
        })
      );
      if (studySiteOrders) {
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "studySiteOrders",
          id: studySiteId,
          payload: studySiteOrders,
        });
        if (shouldFetchBilling) {
          dispatch("fetchBillingReports", studySiteOrders);
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createStudySiteOrder: async (
    { state, dispatch },
    { studyId, studySiteId, order }
  ) => {
    try {
      const success = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "orderReceiverApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteorder`,
        }),
        order
      );
      if (success) {
        const currentLength = state.studySiteOrders[studySiteId]
          ? state.studySiteOrders[studySiteId].length
          : 20;
        dispatch("fetchAllStudySiteOrders", {
          studyId,
          studySiteId,
          length: currentLength >= 20 ? currentLength : currentLength + 1,
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySiteOrder: async ({ commit }, params) => {
    try {
      const studySiteOrder = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "doseOrderManagerApi",
          url: `study/${params.studyId}/studysite/${params.studySiteId}/studysiteorder/${params.studySiteOrderId}`,
        })
      );
      if (studySiteOrder)
        commit("UPDATE_CHILD_ARRAY", {
          property: "studySiteOrders",
          propertyTwo: params.studySiteId,
          idProp: "minervaId",
          payload: studySiteOrder,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  syncStudySiteOrder: async ({ commit }, params) => {
    try {
      const studySiteOrderSync = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "doseOrderManagerApi",
          url: `study/${params.studyId}/studysite/${params.studySiteId}/studysiteorder/${params.order.minervaId}/syncremote`,
        })
      );
      if (studySiteOrderSync)
        commit("MERGE_ITEM_IN_CHILD_ARRAY", {
          property: "studySiteOrders",
          propertyTwo: params.studySiteId,
          idProp: "minervaId",
          payload: { ...studySiteOrderSync, minervaId: params.order.minervaId },
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateOrderSubjectSite: async (state, params) => {
    try {
      await axiosInstance.post(
        buildBaseUrl({
          apiModule: "orderReceiverApi",
          url: `study/${params.studyId}/studysite/${params.studySiteId}/studysiteorder/${params.order.studysiteorder}/admin_local_edit_subjectID`,
        }),
        params.order
      );
      // if (success) {
      //   if (params.order.studysite === params.order.new_studysite) {
      //     dispatch("fetchStudySiteOrder", params);
      //   } else {
      //     commit("DELETE_FROM_ARRAY_BY_ID", {
      //       property: "studySiteOrders",
      //       id: params.studySiteId,
      //       idProp: "minervaId",
      //       payload: {
      //         minervaId: params.studySiteOrderId,
      //       },
      //     });
      //   }
      // }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateOrderCancellation: async ({ dispatch }, params) => {
    try {
      const success = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "orderReceiverApi",
          url: `study/${params.studyId}/studysite/${params.studySiteId}/studysiteorder/${params.orderCancellation.studysiteorder}/admin_local_edit_cancellation`,
        }),
        params.orderCancellation
      );
      if (success) dispatch("fetchStudySiteOrder", params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateStudySiteOrderSubject: async ({ dispatch }, params) => {
    try {
      const success = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "orderReceiverApi",
          url: `study/${params.studyId}/studysite/${params.studySiteId}/studysiteorder/${params.studySiteOrderId}/modify_ordersubject`,
        }),
        params.updatedOrder
      );

      if (success) dispatch("fetchStudySiteOrder", params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateStudySiteOrderNotes: async ({ dispatch }, params) => {
    try {
      const success = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "orderReceiverApi",
          url: `study/${params.studyId}/studysite/${params.studySiteId}/studysiteorder/${params.studySiteOrderId}/modify_ordernotes`,
        }),
        params.updatedOrder
      );

      if (success) dispatch("fetchStudySiteOrder", params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateStudySiteOrderNetwork: async ({ state, dispatch }, params) => {
    try {
      await axiosInstance.post(
        buildBaseUrl({
          apiModule: "orderReceiverApi",
          url: `study/${params.studyId}/studysite/${params.studySiteId}/studysiteorder/${params.studySiteOrderId}/modify_ordernetwork`,
        }),
        params.updatedOrder
      );
    } catch (error) {
      return Promise.reject(error);
    } finally {
      dispatch("fetchAllStudySiteOrders", {
        ...params,
        length: state.studySiteOrders[params.studySiteId].length,
      });
    }
  },
  updateStudySiteOrderDateTime: async ({ dispatch }, params) => {
    try {
      const success = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "orderReceiverApi",
          url: `study/${params.studyId}/studysite/${params.studySiteId}/studysiteorder/${params.studySiteOrderId}/modify_orderdatetime`,
        }),
        params.updatedOrder
      );

      if (success) dispatch("fetchStudySiteOrder", params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateStudySiteOrderCourierNotes: async ({ dispatch }, params) => {
    try {
      const success = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "orderReceiverApi",
          url: `study/${params.studyId}/studysite/${params.studySiteId}/studysiteorder/${params.studySiteOrderId}/modify_ordercouriernotes`,
        }),
        params.updatedOrder
      );

      if (success) dispatch("fetchStudySiteOrder", params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  cancelStudySiteOrder: async ({ dispatch }, params) => {
    try {
      const success = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "orderReceiverApi",
          url: `study/${params.studyId}/studysite/${params.studySiteId}/studysiteorder/${params.studySiteOrderId}/delete_order`,
        }),
        params.payload
      );
      if (success) dispatch("fetchStudySiteOrder", params);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createStudySiteSubject: async (
    { dispatch },
    { studyId, studySiteId, newSubject }
  ) => {
    try {
      const success = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysitesubject`,
        }),
        newSubject
      );
      if (success) dispatch("fetchStudySiteSubjects", { studyId, studySiteId });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySiteSubjects: async ({ commit }, { studyId, studySiteId }) => {
    try {
      const studySiteSubjects = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysitesubject?orderBy=subjectID`,
        })
      );
      if (studySiteSubjects)
        commit("SET_ARRAY_BY_ID", {
          property: "studySiteSubjects",
          id: studySiteId,
          payload: studySiteSubjects,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySiteProducts: async ({ commit }, { studyId, studySiteId }) => {
    try {
      const studySiteProducts = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct`,
        })
      );
      if (studySiteProducts)
        commit("SET_ARRAY_BY_ID", {
          property: "studySiteProducts",
          id: studySiteId,
          payload: studySiteProducts,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySiteNetworks: async (
    { commit },
    { studyId, studySiteId, productId }
  ) => {
    try {
      const studySiteNetworks = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct/${productId}/studysiteproductnetwork?orderBy=priority`,
        })
      );

      if (studySiteNetworks)
        commit("SET_ARRAY_BY_ID", {
          property: "studySiteNetworks",
          id: productId,
          payload: studySiteNetworks.filter(
            (network) => network.ordering_enabled
          ),
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchProductDoseNetwork: async (
    { commit },
    { doseNetworkIds, productId }
  ) => {
    try {
      const productDoseNetworks = await Promise.all(
        doseNetworkIds.map((id) =>
          axiosInstance.get(
            buildBaseUrl({
              apiModule: "doseNetworkManagerApi",
              url: `dosenetwork/${id}`,
            })
          )
        )
      );
      if (productDoseNetworks)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "productNetworkDetails",
          id: productId,
          payload: productDoseNetworks,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchProductionDates: async (
    { commit },
    { availableDateStorageKey, params }
  ) => {
    try {
      const productionDates = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "doseNetworkManagerApi",
          url: "production_dates",
        }),
        params
      );

      if (Object.prototype.hasOwnProperty.call(productionDates, "apiResponse"))
        commit("SET_ARRAY_BY_ID", {
          property: "availableDates",
          id: availableDateStorageKey,
          payload: productionDates.apiResponse,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  changeStudySiteSubject: (context, params) => {
    try {
      console.log(params);
      // const success = axiosInstance.post(
      //   {
      //     apiModule: "doseOrderManagerApi",
      //     url: "change_subject",
      //   },
      //   params.newSubjectDetails
      // );

      // if (success)
      //   dispatch("fetchStudySiteOrder", {
      //     studyId: params.oldStudyId,
      //     studySiteId: params.oldStudySiteId,
      //     studySiteOrderId: params.old_studysiteorder,
      //   });
    } catch (error) {
      Promise.reject(error);
    }
  },
  fetchOrderReportToken: async ({ commit }) => {
    try {
      const orderReportToken = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "doseReportManagerApi",
          url: "studysite_token",
        })
      );
      if (orderReportToken) {
        commit("SET", {
          property: "orderReportToken",
          payload: orderReportToken,
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchOrderSummary: async ({ state, commit }) => {
    try {
      const orderSummary = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "doseReportManagerApi",
          url: `doseorder_summary/${state.orderReportToken}`,
        })
      );
      if (orderSummary)
        commit("SET", {
          property: "orderSummary",
          payload: orderSummary,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchBillingReports: async ({ dispatch }, orders) => {
    try {
      const promises = orders.map((order) =>
        dispatch("fetchBillingReport", order)
      );
      Promise.all([promises]);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchBillingReport: async ({ commit }, params) => {
    try {
      const billingReport = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "doseOrderManagerApi",
          url: `study/${params.study}/studysite/${params.studysite}/orderbillingrecord?order_minerva_id=${params.minervaId}`,
        })
      );
      if (billingReport[0])
        commit("SET_OBJECT_BY_IDPROP", {
          property: "billingReports",
          idProp: "order_minerva_id",
          payload: billingReport[0],
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateBillingReport: async ({ commit }, { params, payload }) => {
    try {
      const billingReport = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "doseOrderManagerApi",
          url: `study/${params.studyId}/studysite/${params.studySiteId}/orderbillingrecord/${params.billingReportId}`,
        }),
        payload
      );
      if (billingReport)
        commit("SET_OBJECT_BY_IDPROP", {
          property: "billingReports",
          idProp: "order_minerva_id",
          payload: billingReport,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchSubjectOtherOrders: async (
    { commit },
    { studyId, studySiteId, subjectId }
  ) => {
    try {
      const otherOrders = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "doseOrderManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/find_last_order_for_subject/${subjectId}`,
        })
      );
      if (otherOrders)
        commit("SET_ARRAY_BY_ID", {
          property: "subjectOtherOrders",
          id: subjectId,
          payload: otherOrders,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
