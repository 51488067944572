<template>
  <div class="layout-topbar">
    <button
      id="left-side-bar-toggle"
      class="p-link layout-menu-button"
      @click="toggleLeftSideBar"
    >
      <span class="pi pi-bars"></span>
    </button>
    <div class="layout-topbar-title p-d-flex">
      <h5 class="p-text-bold p-mr-6 p-pr-4">Minerva</h5>
      <button
        id="right-side-bar-toggle"
        class="p-link layout-menu-button"
        @click="toggleRightSideBar"
      >
        <span
          :data-badge="numberOfNotifications"
          class="pi pi-bell p-mb-3"
        ></span>
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    const numberOfNotifications = computed(
      () => store.state.notifications.notifications.length
    );

    return {
      toggleLeftSideBar: () => store.dispatch("sideBar/toggleLeftSidebar"),
      toggleRightSideBar: () => store.dispatch("sideBar/toggleRightSidebar"),
      numberOfNotifications,
    };
  },
};
</script>
