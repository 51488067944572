import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";
import apiWithThrottling from "@/api/axiosWithMaxRequests";

const state = () => ({
  users: [],
  nonGSAUsers: [],
  userRoles: {},
  userGroups: {},
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchUsers: async ({ commit }) => {
    try {
      const users = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: "minervauser?limit=1000&orderBy=firstName",
        })
      );
      if (users)
        commit("SET", {
          property: "users",
          payload: users,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchNonGSAUsers: async ({ commit }) => {
    try {
      const nonGSAUsers = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: "minervauser?limit=1000&orderBy=firstName&gsa=false",
        })
      );
      if (nonGSAUsers)
        commit("SET", {
          property: "nonGSAUsers",
          payload: nonGSAUsers,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createUser: async ({ commit }, payload) => {
    try {
      const newUser = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: "minervauser",
        }),
        payload
      );
      if (newUser.user)
        commit("ADD_TO_ARRAY", {
          property: "users",
          payload: newUser.user,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateUser: async ({ commit }, payload) => {
    try {
      const updatedUser = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `minervauser/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedUser.user)
        commit("UPDATE_ARRAY", {
          property: "users",
          idProp: "minervaId",
          payload: updatedUser.user,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteUser: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `minervauser/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "users",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  hasRole: async ({ commit, state }, payload) => {
    const resourceId = `${payload.role}-${payload.resource}`;
    try {
      if (Object.prototype.hasOwnProperty.call(state.userRoles, resourceId)) {
        return state.userRoles[resourceId];
      }
      const response = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: "has_role",
        }),
        payload
      );
      if (response)
        commit("SET_ARRAY_BY_ID", {
          property: "userRoles",
          id: resourceId,
          payload: response.permitted,
        });
      return response.permitted;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchUserGroups: async ({ commit, state }, user) => {
    if (
      Object.prototype.hasOwnProperty.call(state.userGroups, user.minervaId)
    ) {
      return state.userGroups[user.minervaId];
    }
    try {
      const groups = await apiWithThrottling.get(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `minervausergroup?minervauser_email=${user.email}`,
        })
      );
      if (groups)
        commit("SET_ARRAY_BY_ID", {
          property: "userGroups",
          id: user.minervaId,
          payload: groups,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addUserGroup: async ({ commit }, payload) => {
    try {
      const newGroups = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `minervausergroup`,
        }),
        {
          minervauser: payload.userId,
          group_name: payload.group.name,
        }
      );
      if (newGroups)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "userGroups",
          id: payload.userId,
          payload: newGroups,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteUserGroup: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `minervausergroup/${payload.deleteGroup.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "userGroups",
          id: payload.userId,
          idProp: "minervaId",
          payload: payload.deleteGroup,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
