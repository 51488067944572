import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  studyAdmins: {},
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchStudyAdmins: async ({ commit }, { studyId }) => {
    try {
      const studyAdmins = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studyadmin`,
        })
      );

      if (studyAdmins)
        commit("SET_ARRAY_BY_ID", {
          property: "studyAdmins",
          id: studyId,
          payload: studyAdmins,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addStudyAdmin: async ({ commit }, { studyId, payload }) => {
    try {
      const newAdmin = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studyadmin`,
        }),
        payload
      );
      if (newAdmin)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "studyAdmins",
          id: studyId,
          payload: newAdmin,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteStudyAdmin: async ({ commit }, { studyId, payload }) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studyadmin/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "studyAdmins",
          id: studyId,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
