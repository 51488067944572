import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  groups: [],
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchGroups: async ({ commit }) => {
    try {
      const groups = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: "group",
        })
      );
      if (groups)
        commit("SET", {
          property: "groups",
          payload: groups,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createGroup: async ({ commit }, payload) => {
    try {
      const newGroup = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: "group",
        }),
        payload
      );
      if (newGroup)
        commit("ADD_TO_ARRAY", {
          property: "groups",
          payload: newGroup,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateGroup: async ({ commit }, payload) => {
    try {
      const updatedGroup = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `group/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedGroup)
        commit("UPDATE_ARRAY", {
          property: "groups",
          idProp: "minervaId",
          payload: updatedGroup,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteGroup: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: `group/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "groups",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
