<template>
  <div class="p-d-flex">
    <Button
      label="Clear All"
      class="p-mx-auto p-mt-4"
      @click="clearAllNotifications"
    />
  </div>
  <div class="p-toast p-component p-m-3">
    <div v-for="notification in notifications" :key="notification.id">
      <div
        :class="containerClass(notification.severity)"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="p-toast-message-content">
          <span :class="iconClass(notification.severity)"></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ notification.summary }}</span>
            <div class="p-toast-detail">{{ notification.detail }}</div>
            <Button
              v-if="notification.moreInfo && notification.moreInfo.length > 0"
              :label="`${showMoreDetails ? 'Hide' : 'More'} Details`"
              class="p-button p-button-sm p-mt-2"
              @click="toggleShowMoreDetails"
            />
            <div v-if="showMoreDetails" class="p-mt-2">
              <span class="p-toast-detail">{{ notification.moreInfo }}</span>
            </div>
          </div>
          <button
            class="p-toast-icon-close p-link"
            type="button"
            @click="removeNotification(notification)"
          >
            <span class="p-toast-icon-close-icon pi pi-times"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";

export default {
  name: "Notifications",
  emits: ["close"],
  setup() {
    const store = useStore();
    const notifications = computed(
      () => store.state.notifications.notifications
    );

    const removeNotification = (notification) => {
      store.dispatch("notifications/removeNotification", notification);
    };

    const clearAllNotifications = () => {
      if (notifications.value.length === 0) return;
      store.dispatch("notifications/clearAllNotifications");
      setTimeout(() => {
        store.dispatch("sideBar/toggleRightSidebar");
      }, 250);
    };

    const containerClass = (severity) => {
      return [
        "p-toast-message",
        {
          "p-toast-message-info": severity === "info",
          "p-toast-message-warn": severity === "warn",
          "p-toast-message-error": severity === "error",
          "p-toast-message-success": severity === "success",
        },
      ];
    };

    const iconClass = (severity) => {
      return [
        "p-toast-message-icon pi",
        {
          "pi-info-circle": severity === "info",
          "pi-exclamation-triangle": severity === "warn",
          "pi-times": severity === "error",
          "pi-check": severity === "success",
        },
      ];
    };

    const showMoreDetails = ref(false);

    const toggleShowMoreDetails = () => {
      showMoreDetails.value = !showMoreDetails.value;
    };

    return {
      notifications,
      removeNotification,
      clearAllNotifications,
      containerClass,
      iconClass,
      showMoreDetails,
      toggleShowMoreDetails,
    };
  },
};
</script>
