import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  products: [],
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchProducts: async ({ commit }) => {
    try {
      const products = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "productManagerApi",
          url: "product?orderBy=name",
        })
      );
      if (products)
        commit("SET", {
          property: "products",
          payload: products,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createProduct: async ({ commit }, payload) => {
    try {
      const newProduct = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "productManagerApi",
          url: "product",
        }),
        payload
      );
      if (newProduct)
        commit("ADD_TO_ARRAY", {
          property: "products",
          payload: newProduct,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateProduct: async ({ commit }, payload) => {
    try {
      const updatedProduct = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "productManagerApi",
          url: `product/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedProduct)
        commit("UPDATE_ARRAY", {
          property: "products",
          idProp: "minervaId",
          payload: updatedProduct,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteProduct: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "productManagerApi",
          url: `product/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "products",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
