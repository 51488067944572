import { createStore, createLogger } from "vuex";
import sideBar from "./modules/sideBar/sideBar";
import users from "./modules/users/users";
import zenDesk from "./modules/users/zenDesk";
import orderManagement from "./modules/orderManagement/orderManagement";
import orderRequests from "./modules/orderManagement/orderRequests";
import orderingAdmins from "./modules/studyManagement/orderingAdmins";
import emailReceivers from "./modules/studyManagement/emailReceivers";
import studyManagement from "./modules/studyManagement/studyManagement";
import studyOrganizations from "./modules/studyManagement/studyOrganizations";
import studySites from "./modules/studyManagement/studySites";
import studySiteProducts from "./modules/studyManagement/studySiteProducts";
import products from "./modules/products/products";
import notifications from "./modules/notifications/notifications";
import pharmacies from "./modules/pharmacies/pharmacies";
import pharmacyOrders from "./modules/pharmacies/pharmacyOrders";
import pharmacyPo from "./modules/pharmacies/pharmacyPo";
import orderConfirmation from "./modules/orderConfirmation/orderConfirmation";
import groups from "./modules/groups/groups";
import organizations from "./modules/organizations/organizations";
import rolesPermissions from "./modules/rolesPermissions/rolesPermissions";
import studyAdmins from "./modules/studyManagement/studyAdmins";
import apiManagement from "./modules/apiManagement/apiManagement";
import activeRoute from "./modules/activeRoute/activeRoute";
// const debug = process.env.NODE_ENV !== "production";
const debug = false;

export default createStore({
  modules: {
    sideBar,
    users,
    zenDesk,
    orderManagement,
    orderRequests,
    studyManagement,
    notifications,
    orderingAdmins,
    emailReceivers,
    studyOrganizations,
    studySites,
    studySiteProducts,
    products,
    pharmacies,
    pharmacyOrders,
    pharmacyPo,
    orderConfirmation,
    groups,
    organizations,
    rolesPermissions,
    studyAdmins,
    apiManagement,
    activeRoute,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
