import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";
import apiWithThrottling from "@/api/axiosWithMaxRequests";

const state = () => ({
  studySite: {},
  studySites: {},
  studySiteUsers: {},
  studySiteEmailReceivers: {},
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchStudySite: async ({ commit }, { studyId, siteId }) => {
    try {
      const studySite = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${siteId}`,
        })
      );

      if (studySite)
        commit("SET", {
          property: "studySite",
          payload: studySite,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySites: async ({ commit }, { studyId }) => {
    try {
      const studySites = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite?limit=1000`,
        })
      );

      if (studySites)
        commit("SET_ARRAY_BY_ID", {
          property: "studySites",
          id: studyId,
          payload: studySites,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addStudySite: async ({ commit }, { studyId, payload }) => {
    try {
      const newStudySite = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite`,
        }),
        payload
      );
      if (newStudySite)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "studySites",
          id: studyId,
          payload: newStudySite,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateStudySite: async ({ commit }, { studyId, payload }) => {
    try {
      const updatedStudySite = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedStudySite)
        commit("UPDATE_CHILD_ARRAY", {
          property: "studySites",
          propertyTwo: studyId,
          idProp: "minervaId",
          payload: updatedStudySite,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteStudySite: async ({ commit }, { studyId, payload }) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "studySites",
          id: studyId,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySiteUsers: async ({ commit }, { studyId, studySiteId }) => {
    try {
      const studySiteUsers = await apiWithThrottling.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteuser`,
        })
      );
      if (studySiteUsers)
        commit("SET_ARRAY_BY_ID", {
          property: "studySiteUsers",
          id: studySiteId,
          payload: studySiteUsers,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addStudySiteUser: async ({ commit }, { studyId, studySiteId, payload }) => {
    try {
      const newUser = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteuser`,
        }),
        payload
      );
      if (newUser) {
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "studySiteUsers",
          id: studySiteId,
          payload: newUser.user,
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteStudySiteUser: async (
    { commit },
    { studyId, studySiteId, payload }
  ) => {
    try {
      const success = axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteuser/${payload.minervaId}`,
        })
      );
      if (success) {
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "studySiteUsers",
          id: studySiteId,
          idProp: "minervaId",
          payload,
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySiteEmailReceivers: async (
    { commit },
    { studyId, studySiteId }
  ) => {
    try {
      const studySiteEmailReceivers = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysitedoseorderemailreceiver`,
        })
      );
      if (studySiteEmailReceivers)
        commit("SET_ARRAY_BY_ID", {
          property: "studySiteEmailReceivers",
          id: studySiteId,
          payload: studySiteEmailReceivers,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addStudySiteEmailReceiver: async (
    { commit },
    { studyId, studySiteId, payload }
  ) => {
    try {
      const newUser = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysitedoseorderemailreceiver`,
        }),
        payload
      );
      if (newUser) {
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "studySiteEmailReceivers",
          id: studySiteId,
          payload: newUser,
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteStudySiteEmailReceiver: async (
    { commit },
    { studyId, studySiteId, payload }
  ) => {
    try {
      const success = axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysitedoseorderemailreceiver/${payload.minervaId}`,
        })
      );
      if (success) {
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "studySiteEmailReceivers",
          id: studySiteId,
          idProp: "minervaId",
          payload,
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
