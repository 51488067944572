import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  pharmacyPo: {},
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchPharmacyPos: async ({ commit }, { pharmacyId }) => {
    try {
      const PharmacyPo = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${pharmacyId}/pharmacypo`,
        })
      );

      if (PharmacyPo)
        commit("SET_ARRAY_BY_ID", {
          property: "pharmacyPo",
          id: pharmacyId,
          payload: PharmacyPo,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createPharmacyPo: async ({ commit }, { pharmacyId, payload }) => {
    try {
      const newPharmacyPo = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${pharmacyId}/pharmacypo`,
        }),
        payload
      );
      if (newPharmacyPo)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "pharmacyPo",
          id: pharmacyId,
          payload: newPharmacyPo,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updatePharmacyPo: async ({ commit }, { pharmacyId, payload }) => {
    console.log(payload);
    try {
      const updatedPharmacyPo = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${pharmacyId}/pharmacypo/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedPharmacyPo)
        commit("UPDATE_CHILD_ARRAY", {
          property: "pharmacyPo",
          propertyTwo: pharmacyId,
          idProp: "minervaId",
          payload: updatedPharmacyPo,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deletePharmacyPo: async ({ commit }, { pharmacyId, payload }) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${pharmacyId}/pharmacypo/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "pharmacyPo",
          id: pharmacyId,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
