import axios from "axios";
import Auth from "@/auth";
// import router for 404 error handling
// update interceptors as needed to handle errors
const instance = axios.create();
instance.defaults.headers.common["Accept"] = "application/json";
instance.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
instance.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

instance.interceptors.request.use(async (config) => {
  const token = await Auth.getTokenSilently();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(
  (response) => response.data.data,
  (error) => {
    if (error.response.status && error.response.status === 403) {
      return Promise.reject("You are unauthorized to perform this action.");
    }
    const err = error?.response?.data?.error || false;

    if (err) {
      // sending full message for now and showing it in a details button
      // const properties = err.split("\n");
      // const errs = [];
      // properties.forEach((property) => {
      //   const tup = property.split("Error:");
      //   errs.push(tup[1]);
      // });
      // return Promise.reject(errs.join(", "));
      return Promise.reject(err);
    }

    return Promise.reject(error.message);
  }
);

export default instance;
