export default [
  {
    label: "Order Management",
    system_key: "dose_order",
    icon: "pi pi-fw pi-file",
    class: "side-bar-item",
    to: "/orderReports",
    items: [
      {
        label: "Report",
        to: "/orderReports",
        class: "side-bar-item",
      },
      {
        label: "Orders",
        to: "/orders",
        class: "side-bar-item",
      },
      {
        label: "Order Summary",
        to: "/orderSummary",
        class: "side-bar-item",
      },
    ],
  },
  {
    label: "Pharmacy Orders",
    system_key: "pharmacy_order",
    icon: "pi pi-fw pi-folder-open",
    to: "/pharmacyOrders",
    class: "side-bar-item",
  },
  {
    label: "Study Management",
    system_key: "studymgmt",
    icon: "pi pi-fw pi-sitemap",
    to: "/studies",
    class: "side-bar-item",
    items: [
      {
        label: "Studies",
        to: "/studies",
        class: "side-bar-item",
      },
      {
        label: "Study Administrators",
        to: "/studyAdministrators",
        class: "side-bar-item",
      },
      {
        label: "Sites",
        to: "/sites",
        class: "side-bar-item",
      },

      { label: "Products", to: "/products", class: "side-bar-item" },
      { label: "Networks", to: "/networks", class: "side-bar-item" },
    ],
  },
  {
    label: "Pharmacy Management",
    system_key: "pharmacymgmt",
    icon: "pi pi-fw pi-briefcase",
    to: "/pharmacies",
    class: "side-bar-item",
    items: [
      {
        label: "Pharmacies",
        to: "/pharmacies",
        class: "side-bar-item",
      },
      {
        label: "Pharmacy Administrators",
        to: "/pharmacyAdministrators",
        class: "side-bar-item",
      },
      {
        label: "Pharmacy Maps",
        to: "/pharmacyMaps",
        class: "side-bar-item",
      },
    ],
  },
  {
    label: "User Management",
    system_key: "usermgmt",
    icon: "pi pi-fw pi-id-card",
    to: "/users",
    class: "side-bar-item",
    items: [
      {
        label: "Users",
        to: "/users",
        class: "side-bar-item",
      },
      {
        label: "Groups",
        to: "/groups",
        class: "side-bar-item",
      },
      {
        label: "Organizations",
        to: "/organizations",
        class: "side-bar-item",
      },
      {
        label: "Roles/Permissions",
        to: "/rolesPermissions",
        class: "side-bar-item",
      },
      {
        label: "User: Roles",
        to: "/userRoles",
        class: "side-bar-item",
      },
      {
        label: "Group: Roles",
        to: "/groupRoles",
        class: "side-bar-item",
      },
    ],
  },
  {
    label: "Api Management",
    system_key: "api_management",
    icon: "pi pi-fw pi-folder-open",
    to: "/apiManagement",
    class: "side-bar-item",
  },
];
