<template>
  <div v-if="user" :class="containerClass">
    <AppTopBar />

    <transition name="layout-left-sidebar">
      <div
        v-show="openLeftSidebar"
        class="layout-left-sidebar layout-sidebar-light"
      >
        <div class="layout-logo">
          <router-link to="/">
            <img
              src="@/assets/MBPLogo_Orig.svg"
              alt="Minerva Logo"
              class="minerva-logo"
            />
          </router-link>
        </div>

        <div class="layout-profile">
          <p class="username">{{ username }}</p>
          <br />
          <Button class="p-button-outlined p-button-sm" @click="logOut"
            >Logout</Button
          >
          <br />
        </div>
        <AppMenu :model="availableSideMenuItems" />
      </div>
    </transition>

    <transition name="layout-right-sidebar">
      <div
        v-show="openRightSidebar"
        class="layout-right-sidebar layout-sidebar-light"
      >
        <Notifications />
      </div>
    </transition>
    <div class="layout-main">
      <router-view />
      <div
        v-if="
          $route.path === '/orderReports' &&
          orderReportTokenApiResource.getLoading()
        "
        class="loader-wrapper p-d-flex p-ai-center p-jc-center"
      >
        <ProgressSpinner />
      </div>
      <GoogleDataStudioReport
        v-show="$route.path === '/orderReports'"
        :key="orderReportURL"
        :report-u-r-l="orderReportURL"
      />
      <!-- <Overview v-show="$route.path === '/overview'"/>
      <OrderReports  v-show="$route.path === '/orderReports'"/> -->
    </div>
  </div>
  <div v-if="!user">
    <AppLoading />
  </div>
  <Toast position="bottom-center" :base-z-index="1500" />
  <ConfirmDialog></ConfirmDialog>
</template>

<script>
import { ref, inject, computed, watch, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import IdleJs from "idle-js";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import ProgressSpinner from "primevue/progressspinner";
import ConfirmDialog from "primevue/confirmdialog";

import AppLoading from "@/views/AppLoading.vue";
import GoogleDataStudioReport from "@/views/GoogleDataStudioReport";
// import OrderReports from "@/views/OrderReports";
// import Overview from "@/views/Overview";
import AppTopBar from "@/components/AppTopbar/AppTopbar.vue";
import AppMenu from "@/components/AppMenu/AppMenu.vue";
import Notifications from "@/components/Notifications/Notifications.vue";
import apiDict from "@/api/apiDict";
import { getUserRole } from "@/helpers/getUserRole";
import sideMenuItems from "@/helpers/sideMenuItems";

export default {
  name: "App",
  components: {
    AppTopBar,
    AppMenu,
    Toast,
    ConfirmDialog,
    Notifications,
    ProgressSpinner,
    AppLoading,
    // OrderReports,
    // Overview,
    GoogleDataStudioReport,
  },
  setup() {
    const auth = inject("Auth");
    const ApiResource = inject("ApiResource");
    const user = auth?.user?.value ?? undefined;

    // const permissions = inject("Permissions");
    // on app startup, build permissions objects for use

    // if (auth.user.value) {
    //   (async () => {
    //     await permissions.buildPermissions(auth.user.value);
    //   })();
    // }

    const idle = new IdleJs({
      idle: 1500000,
      events: ["mousemove", "keydown", "mousedown", "touchstart"],
      onIdle() {
        logOut();
      },
    });
    idle.start();

    const store = useStore();
    const openLeftSidebar = computed(() => store.state.sideBar.openLeftSidebar);
    const openRightSidebar = computed(
      () => store.state.sideBar.openRightSidebar
    );

    const containerClass = computed(() => [
      "layout-wrapper",
      "layout-static",
      "p-input-filled",
      openLeftSidebar.value
        ? "layout-static-left-sidebar-active"
        : "layout-static-left-sidebar-inactive",
      openRightSidebar.value
        ? "layout-static-right-sidebar-active"
        : "layout-static-right-sidebar-inactive",
    ]);

    const route = useRoute();
    const toast = useToast();
    const router = useRouter();

    watch(route, () => {
      toast.removeAllGroups();
    });

    const orderAdminUser = computed(() => {
      const orderRoles = getUserRole(auth.user.value, "dose_order");
      if (orderRoles) {
        return orderRoles.some(
          (role) =>
            role.role === "SuperAdmin" || role.role === "StudyDoseOrderAdmin"
        );
      }
      return false;
    });

    const apiAccessPermission = ref(false);

    const getApiAccessPermission = () =>
      store.dispatch("users/hasRole", {
        role: "ApiAdmin",
        email: user.email,
        resource: `/minerva`,
      });

    const checkApiAccessPermission = new ApiResource(
      undefined,
      getApiAccessPermission,
      undefined,
      undefined
    );

    const availableSideMenuItems = computed(() => {
      if (auth.user.value) {
        const roles = Object.keys(
          auth.user.value["https://minerva.cereb.io/custom_claims"].systems
        );

        const items = [];

        for (const item of sideMenuItems) {
          if (
            item.system_key === "all_users" ||
            roles.includes(item.system_key)
          ) {
            if (!orderAdminUser.value && item.system_key === "dose_order") {
              const tempItem = { ...item };
              tempItem.items = item.items.filter(
                (i) => i.to === "/orderReports" || i.to === "/orders"
              );
              tempItem.to = "/orderReports";
              items.push(tempItem);
            } else {
              items.push(item);
            }
          }
          if (
            item.system_key === "api_management" &&
            apiAccessPermission.value
          ) {
            items.push(item);
          }
        }

        return items;
      } else {
        return sideMenuItems[0];
      }
    });

    const logOut = () => {
      clearInterval(zenDeskTokenRefreshTimer);
      if (process.env.VUE_APP_ENVIRONMENT == "PRODUCTION") {
        window.zE("messenger", "logoutUser");
      }
      auth.logout({
        returnTo: window.location.origin,
      });
    };

    const fetchOrderReportToken = () =>
      store.dispatch("orderManagement/fetchOrderReportToken");
    const orderReportTokenApiResource = new ApiResource(
      undefined,
      fetchOrderReportToken,
      undefined,
      "There was an error fetching your order report access code."
    );

    const orderReportURL = ref("");

    const buildReportURL = (token, apiUrl, apiKey) => {
      if (!apiDict[apiUrl]) {
        throw "Invalid API url";
      }
      if (!apiDict[apiKey]) {
        throw "Invalid API key";
      }
      console.log(apiDict[apiKey]);
      const params = {
        [apiDict[apiKey]]: token,
      };
      const paramsAsString = JSON.stringify(params);
      const encodedParams = encodeURIComponent(paramsAsString);
      return `${apiDict[apiUrl]}?params=${encodedParams}`;
    };

    const unWatch = watch(
      () => store.state.orderManagement.orderReportToken,
      (token) => {
        if (token.length > 0) {
          if (orderAdminUser.value) {
            orderReportURL.value = buildReportURL(
              token,
              "doseOrderReportUrl",
              "doseOrderReportKey"
            );
          } else {
            orderReportURL.value = buildReportURL(
              token,
              "doseOverviewReportUrl",
              "doseOverviewReportKey"
            );
          }
          unWatch();
        }
      },
      { immediate: true }
    );

    const setIndex = (mainRoute, subRoute = "") => {
      const mainIndex = availableSideMenuItems.value.findIndex(
        (item) => item.to === mainRoute
      );
      if (mainIndex === -1) return;
      const mainItem = availableSideMenuItems.value[mainIndex];
      store.commit("activeRoute/SET_MAIN_ACTIVE_INDEX", mainIndex);
      if (subRoute.length > 0 && mainItem.items && mainItem.items.length > 0) {
        const subItemIndex = mainItem.items.findIndex(
          (item) => item.to === subRoute
        );
        if (subItemIndex === -1) return;
        store.commit("activeRoute/SET_SUB_ACTIVE_INDEX", subItemIndex);
      }
    };

    const zenDeskToken = () => store.state.zenDesk.zenDeskToken;
    const fetchZenDeskToken = () => store.dispatch("zenDesk/fetchZenDeskToken");
    const zenDeskTokenApiResource = new ApiResource(
      zenDeskToken,
      fetchZenDeskToken,
      undefined,
      undefined
    );

    let zenDeskTokenRefreshTimer = undefined;

    const initiateZenDeskTokenRefresh = () => {
      zenDeskTokenRefreshTimer = setInterval(async () => {
        await zenDeskTokenApiResource.fetchResource();
        const token = zenDeskTokenApiResource.getData();
        if (token) {
          window.zE("messenger", "loginUser", function (callback) {
            callback(token);
            initiateZenDeskTokenRefresh();
          });
        }
      }, 900000);
    };

    onMounted(async () => {
      if (process.env.VUE_APP_ENVIRONMENT == "PRODUCTION") {
        const zenDeskKey = apiDict.zenDeskKey;
        if (zenDeskKey) {
          let zenDeskWidget = document.createElement("script");
          zenDeskWidget.setAttribute("type", "text/javascript");
          zenDeskWidget.setAttribute("id", "ze-snippet");
          zenDeskWidget.setAttribute("async", true);
          zenDeskWidget.setAttribute(
            "src",
            `https://static.zdassets.com/ekr/snippet.js?key=${zenDeskKey}`
          );
          document.head.appendChild(zenDeskWidget);
          await zenDeskTokenApiResource.fetchResource();
          const token = zenDeskTokenApiResource.getData();
          if (token) {
            // slight delay to give the zenDeskWidget time to init
            setTimeout(() => {
              window.zE("messenger", "loginUser", function (callback) {
                callback(token);
                if (initiateZenDeskTokenRefresh == undefined) {
                  initiateZenDeskTokenRefresh();
                }
              });
            }, 1000);
          }
        }
      }
      if (auth.user.value) {
        const isSparrow = auth.isSparrow.value;
        apiAccessPermission.value = await checkApiAccessPermission.fetchResource();
        orderReportTokenApiResource.fetchResource();
        const roles = Object.keys(
          auth.user.value["https://minerva.cereb.io/custom_claims"].systems
        );
        if (roles.includes("dose_order")) {
          store.commit("activeRoute/SET_HOME_ROUTE", "/orderReports");
          if (!isSparrow) router.push("/orderReports");
          setIndex("/orderReports", "/orderReports");
          return;
        }
        if (roles.includes("pharmacy_order")) {
          store.commit("activeRoute/SET_HOME_ROUTE", "/pharmacyOrders");
          if (!isSparrow) router.push("/pharmacyOrders");
          setIndex("/pharmacyOrders");
          return;
        }
        store.commit("activeRoute/SET_HOME_ROUTE", "/studies");
        if (!isSparrow) router.push("/studies");
        setIndex("/studies", "/studies");
      }
      auth.resetIsSparrow();
    });

    onUnmounted(() => {
      clearInterval(zenDeskTokenRefreshTimer);
    });

    return {
      username: auth.user.value?.name,
      user: auth.user.value,
      availableSideMenuItems,
      containerClass,
      openLeftSidebar,
      openRightSidebar,
      logOut,
      orderReportURL,
      orderReportTokenApiResource,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/App.scss";
.minerva-logo {
  width: 140px;
  height: 140px;
}
.loader-wrapper {
  width: 100%;
  height: 97vh;
}
</style>
