import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  emailReceivers: {},
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchEmailReceivers: async ({ commit }, { studyId }) => {
    try {
      const emailReceivers = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studydoseorderemailreceiver`,
        })
      );

      if (emailReceivers)
        commit("SET_ARRAY_BY_ID", {
          property: "emailReceivers",
          id: studyId,
          payload: emailReceivers,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addEmailReceiver: async ({ commit }, { studyId, payload }) => {
    try {
      const newEmailReceiver = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studydoseorderemailreceiver`,
        }),
        payload
      );
      if (newEmailReceiver)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "emailReceivers",
          id: studyId,
          payload: newEmailReceiver,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteEmailReceiver: async ({ commit }, { studyId, payload }) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studydoseorderemailreceiver/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "emailReceivers",
          id: studyId,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
