<template>
  <ul v-if="items">
    <template v-for="(item, i) of items">
      <li
        v-if="visible(item) && !item.separator"
        :key="item.label"
        :class="[
          {
            'active-menuitem': activeIndex === i && !item.to && !item.disabled,
          },
        ]"
        role="none"
      >
        <div v-if="item.items && root === true" class="arrow"></div>
        <a
          v-if="item.to"
          :id="item.label.replace(' ', '')"
          v-ripple
          :class="[
            item.class,
            'p-ripple',
            {
              'active-route': activeIndex === i,
              'router-link-active': activeIndex === i,
              'p-disabled': item.disabled,
            },
          ]"
          :style="item.style"
          :target="item.target"
          exact
          role="menuitem"
          @click="onMenuItemClick($event, item, i)"
        >
          <i :class="item.icon"></i>
          <span>{{ item.label }}</span>
          <i
            v-if="item.items"
            class="pi pi-fw pi-angle-down menuitem-toggle-icon"
          ></i>
          <span v-if="item.badge" class="menuitem-badge">{{ item.badge }}</span>
        </a>
        <a
          v-if="!item.to"
          v-ripple
          :href="item.url || '#'"
          :style="item.style"
          :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
          :target="item.target"
          role="menuitem"
          @click="onMenuItemClick($event, item, i)"
        >
          <i :class="item.icon"></i>
          <span>{{ item.label }}</span>
          <i
            v-if="item.items"
            class="pi pi-fw pi-angle-down menuitem-toggle-icon"
          ></i>
          <span v-if="item.badge" class="menuitem-badge">{{ item.badge }}</span>
        </a>
        <transition name="layout-submenu-wrapper">
          <AppSubmenu
            v-show="activeIndex === i"
            :is-sub-menu="true"
            :items="visible(item) && item.items"
          ></AppSubmenu>
        </transition>
      </li>
      <li
        v-if="visible(item) && item.separator"
        :key="'separator' + item.label"
        class="p-menu-separator"
        :style="item.style"
        role="separator"
      ></li>
    </template>
  </ul>
</template>
<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "AppSubmenu",
  components: {
    AppSubmenu: this,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    root: {
      type: Boolean,
      default: false,
    },
    isSubMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const activeIndex = computed(
      () =>
        store.state.activeRoute[
          props.isSubMenu ? "subActiveIndex" : "mainActiveIndex"
        ]
    );

    const onMenuItemClick = (event, item, index) => {
      if (item.disabled) {
        event.preventDefault();
        return;
      }

      if (!item.to && !item.url) {
        event.preventDefault();
      }

      //execute command
      if (item.command) {
        item.command({ originalEvent: event, item: item });
      }

      if (props.isSubMenu) {
        store.commit("activeRoute/SET_SUB_ACTIVE_INDEX", index);
      } else {
        store.commit("activeRoute/SET_MAIN_ACTIVE_INDEX", index);
        store.commit("activeRoute/SET_SUB_ACTIVE_INDEX", 0);
      }

      if (item.to) {
        router.push(item.to);
      }
    };

    const visible = (item) => {
      return typeof item.visible === "function"
        ? item.visible()
        : item.visible !== false;
    };

    return {
      activeIndex,
      onMenuItemClick,
      visible,
    };
  },
};
</script>