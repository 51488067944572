import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  zenDeskToken: "",
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchZenDeskToken: async ({ commit }) => {
    try {
      const zenDeskToken = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "userManagerApi",
          url: "gen_external_auth_token",
        }),
        { service: "zendesk" }
      );
      if (zenDeskToken)
        commit("SET", {
          property: "zenDeskToken",
          payload: zenDeskToken,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
