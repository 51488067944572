import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  studyOperators: {},
  studyObservers: {},
  studySponsors: {},
  allOrgs: [],
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchAllOrganizations: async ({ commit }) => {
    try {
      const allOrgs = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "organizationServerApi",
          url: "organization",
        })
      );
      if (allOrgs)
        commit("SET", {
          property: "allOrgs",
          payload: allOrgs,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudyOperators: async ({ commit }, { studyId }) => {
    try {
      const studyOperators = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studyoperator`,
        })
      );

      if (studyOperators)
        commit("SET_ARRAY_BY_ID", {
          property: "studyOperators",
          id: studyId,
          payload: studyOperators,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addStudyOperator: async ({ commit }, { studyId, payload }) => {
    try {
      const newStudyOperator = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studyoperator`,
        }),
        payload
      );
      if (newStudyOperator)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "studyOperators",
          id: studyId,
          payload: newStudyOperator,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteStudyOperator: async ({ commit }, { studyId, payload }) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studyoperator/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "studyOperators",
          id: studyId,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudyObservers: async ({ commit }, { studyId }) => {
    try {
      const studyObservers = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studyobserver`,
        })
      );

      if (studyObservers)
        commit("SET_ARRAY_BY_ID", {
          property: "studyObservers",
          id: studyId,
          payload: studyObservers,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addStudyObserver: async ({ commit }, { studyId, payload }) => {
    try {
      const newStudyObserver = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studyobserver`,
        }),
        payload
      );
      if (newStudyObserver)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "studyObservers",
          id: studyId,
          payload: newStudyObserver,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteStudyObserver: async ({ commit }, { studyId, payload }) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studyobserver/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "studyObservers",
          id: studyId,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySponsors: async ({ commit }, { studyId }) => {
    try {
      const studySponsors = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysponsor`,
        })
      );

      if (studySponsors)
        commit("SET_ARRAY_BY_ID", {
          property: "studySponsors",
          id: studyId,
          payload: studySponsors,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addStudySponsor: async ({ commit }, { studyId, payload }) => {
    try {
      const newStudySponsor = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysponsor`,
        }),
        payload
      );
      if (newStudySponsor)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "studySponsors",
          id: studyId,
          payload: newStudySponsor,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteStudySponsor: async ({ commit }, { studyId, payload }) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysponsor/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "studySponsors",
          id: studyId,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
