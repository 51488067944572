const state = () => ({
  mainActiveIndex: null,
  subActiveIndex: null,
  homeRoute: "/",
});

const mutations = {
  SET_MAIN_ACTIVE_INDEX: (state, index) => {
    state.mainActiveIndex = index;
  },
  SET_SUB_ACTIVE_INDEX: (state, index) => {
    state.subActiveIndex = index;
  },
  SET_HOME_ROUTE: (state, route) => {
    state.homeRoute = route;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
