const envPrefixes = {
  DEVELOPMENT: "TEST",
  TESTING: "TEST",
  PRODUCTION: "PRODUCTION",
  PREPROD_US: "PREPROD",
};

const environment = envPrefixes[process.env.VUE_APP_ENVIRONMENT];

if (!environment) {
  throw new Error(
    `Invalid environment variable: ${process.env.VUE_APP_ENVIRONMENT}`
  );
}

export default {
  userManagerApi: process.env[`VUE_APP_${environment}_USERMAN_API`],
  siteManagerApi: process.env[`VUE_APP_${environment}_SITEINFO_API`],
  organizationServerApi: process.env[`VUE_APP_${environment}_ORGANIZATION_API`],
  studyManagerApi: process.env[`VUE_APP_${environment}_STUDYMAN_API`],
  productManagerApi: process.env[`VUE_APP_${environment}_PRODMAN_API`],
  orderReceiverApi: process.env[`VUE_APP_${environment}_ORDERRECEIVER_API`],
  pharmacyInfoApi: process.env[`VUE_APP_${environment}_PHARMACYINFO_API`],
  pharmacyMapperApi: process.env[`VUE_APP_${environment}_PHARMACYMAPPER_API`],
  pharmacyManagerApi: process.env[`VUE_APP_${environment}_PHARMACYMANAGER_API`],
  sparrowManagerApi: process.env[`VUE_APP_${environment}_SPARROWMANAGER_API`],
  doseNetworkManagerApi: process.env[`VUE_APP_${environment}_DOSENETWORK_API`],
  doseOrderManagerApi: process.env[`VUE_APP_${environment}_DOSEORDERMAN_API`],
  doseReportManagerApi:
    process.env[`VUE_APP_${environment}_DOSEREPORTMANAGER_API`],
  viewHelperApi: process.env[`VUE_APP_${environment}_VIEWHELPER_API`],
  doseOrderReportUrl: process.env[`VUE_APP_${environment}_ORDER_REPORT_URL`],
  apiManagerUrl: process.env[`VUE_APP_${environment}_API_MANAGEMENT`],
  doseOverviewReportUrl:
    process.env[`VUE_APP_${environment}_OVERVIEW_REPORT_URL`],
  doseOverviewReportKey:
    process.env[`VUE_APP_${environment}_OVERVIEW_REPORT_KEY`],
  doseOrderReportKey: process.env[`VUE_APP_${environment}_ORDER_REPORT_KEY`],
  zenDeskKey: process.env[`VUE_APP_${environment}_ZENDESK_KEY`],
};
