import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  apis: [],
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchApis: async ({ commit }) => {
    try {
      const apis = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "apiManagerUrl",
          url: "minervaapi",
        })
      );
      if (apis)
        commit("SET", {
          property: "apis",
          payload: apis,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createApi: async ({ commit }, payload) => {
    try {
      const newApi = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "apiManagerUrl",
          url: "minervaapi",
        }),
        payload
      );
      if (newApi)
        commit("ADD_TO_ARRAY", {
          property: "apis",
          payload: newApi,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateApi: async ({ commit }, payload) => {
    try {
      const updatedApi = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "apiManagerUrl",
          url: `minervaapi/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedApi)
        commit("UPDATE_ARRAY", {
          property: "apis",
          idProp: "minervaId",
          payload: updatedApi,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteApi: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "apiManagerUrl",
          url: `minervaapi/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "apis",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
