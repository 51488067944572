import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  sparrowObject: {},
  studySiteOrder: {},
  pharmacyOrder: {},
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchSparrowDetails: async ({ commit }, sparrowId) => {
    try {
      const sparrowDetails = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "sparrowManagerApi",
          url: `sparrow/${sparrowId}`,
        })
      );
      if (sparrowDetails)
        commit("SET", {
          property: "sparrowObject",
          payload: sparrowDetails,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySiteOrder: async ({ commit }, params) => {
    try {
      const studySiteOrder = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "doseOrderManagerApi",
          url: `study/${params.studyId}/studysite/${params.studySiteId}/studysiteorder/${params.studySiteOrderId}`,
        })
      );
      if (studySiteOrder)
        commit("SET", {
          property: "studySiteOrder",
          payload: studySiteOrder,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchPharmacyOrder: async ({ commit }, { pharmacyId, pharmacyOrderId }) => {
    try {
      const pharmacyOrder = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyManagerApi",
          url: `pharmacy/${pharmacyId}/pharmacy_order/${pharmacyOrderId}`,
        })
      );
      if (pharmacyOrder)
        commit("SET", { property: "pharmacyOrder", payload: pharmacyOrder });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
