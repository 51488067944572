import vuexMutations from "@/helpers/vuexMutationsHelpers";

const state = () => ({
  notifications: [],
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  addNotification: ({ commit, state }, notification) => {
    if (!notification.severity)
      throw new Error("notifications must have a severity");
    if (!notification.detail)
      throw new Error("notifications must have a detail");
    if (!notification.summary)
      throw new Error("notifications must have a summary");

    const notificationId = genRandomId(
      state.notifications.map((notification) => notification.id)
    );
    commit("ADD_TO_ARRAY", {
      property: "notifications",
      payload: {
        id: notificationId,
        ...notification,
      },
    });
  },
  removeNotification: ({ commit }, notification) => {
    commit("DELETE_FROM_ARRAY", {
      property: "notifications",
      idProp: "id",
      payload: notification,
    });
  },
  clearAllNotifications: ({ commit }) => {
    commit("REPLACE_STATE", {
      property: "notifications",
      newEntity: [],
    });
  },
};

const genRandomId = (previousSelections) => {
  let newId = Math.random();
  if (previousSelections) {
    while (previousSelections.includes(newId)) {
      newId = Math.random();
    }
  }
  return newId;
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
