<template>
  <div
    class="dose-report-container"
    :class="
      openLeftSidebar
        ? 'dose-report-container-padding-side-bar-open'
        : 'dose-report-container-padding-side-bar-closed'
    "
  >
    <iframe
      :key="reportURL"
      ref="reportRef"
      class="dose-report"
      frameborder="0"
      style="border: 0"
    ></iframe>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "GoogleDataStudioReport",

  props: {
    reportURL: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();

    const openLeftSidebar = computed(() => store.state.sideBar.openLeftSidebar);

    const reportRef = ref();

    onMounted(() => {
      reportRef.value.contentWindow.location.replace(props.reportURL);
    });
    return {
      openLeftSidebar,
      reportRef,
    };
  },
};
</script>

<style scoped>
.dose-report-container {
  position: relative;
  overflow: hidden;
}
.dose-report-container-padding-side-bar-open {
  padding-top: 60%;
}
.dose-report-container-padding-side-bar-closed {
  padding-top: 52%;
}
.dose-report {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>