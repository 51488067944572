import { createRouter, createWebHistory } from "vue-router";
import Auth from "@/auth";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/blank",
      name: "blank",
      // blank used here and below, component is rendered directly in app.vue so it doesn't de-render on route changes and have to re-load.
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/Blank"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/overview",
      name: "overview",
      // blank used here and below, component is rendered directly in app.vue so it doesn't de-render on route changes and have to re-load.
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/Blank"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/orders",
      name: "orders",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/OrderManagement"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/orderReports",
      name: "orderReports",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/Blank"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/orderSummary",
      name: "orderSummary",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/OrderSummary"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/pharmacyOrders",
      name: "pharmacyOrders",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/PharmacyOrders"),
      beforeEnter: Auth.routeGuard,
    },

    {
      path: "/studies",
      name: "studies",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/Studies"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/studyAdministrators",
      name: "studyAdministrators",
      component: () =>
        import(
          /* webpackChunkName: "main-pages" */ "@/views/StudyAdministrators"
        ),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/sites",
      name: "sites",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/Sites"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/products",
      name: "products",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/Products"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/networks",
      name: "networks",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/Networks"),
      beforeEnter: Auth.routeGuard,
    },

    {
      path: "/pharmacies",
      name: "pharmacies",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/Pharmacies"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/pharmacyAdministrators",
      name: "pharmacyAdministrators",
      component: () =>
        import(
          /* webpackChunkName: "main-pages" */ "@/views/PharmacyAdministrators"
        ),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/pharmacyMaps",
      name: "pharmacyMaps",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/PharmacyMaps"),
      beforeEnter: Auth.routeGuard,
    },

    {
      path: "/users",
      name: "users",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/Users"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/groups",
      name: "groups",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/Groups"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/organizations",
      name: "organizations",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/Organizations"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/rolesPermissions",
      name: "rolesPermissions",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/RolesPermissions"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/userRoles",
      name: "userRoles",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/UserRoles"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/groupRoles",
      name: "groupRoles",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/GroupRoles"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/apiManagement",
      name: "apiManagement",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/ApiManagement"),
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/sparrow_dt_confirm/:sparrowId",
      name: "sparrow_dt_confirm",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/OrderConfirm"),
      props: true,
      beforeEnter: Auth.routeGuard,
    },
    {
      path: "/:pathMatch(.*)",
      name: "NotFound",
      component: () =>
        import(/* webpackChunkName: "main-pages" */ "@/views/NotFound"),
      beforeEnter: Auth.routeGuard,
    },
  ],
});

export default router;
