import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";
import apiWithThrottling from "@/api/axiosWithMaxRequests";

const state = () => ({
  studySiteProducts: {},
  studySiteProductNetworks: {},
  studySiteProductNetworkPrices: {},
  studySiteProductNetworkFreight: {},
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchStudySiteProducts: async ({ commit }, { studyId, studySiteId }) => {
    try {
      const studySiteProducts = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct`,
        })
      );

      if (studySiteProducts)
        commit("SET_ARRAY_BY_ID", {
          property: "studySiteProducts",
          id: `${studyId}${studySiteId}`,
          payload: studySiteProducts,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addStudySiteProduct: async (
    { commit },
    { studyId, studySiteId, payload }
  ) => {
    try {
      const newStudySiteProduct = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct`,
        }),
        payload
      );
      if (newStudySiteProduct)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "studySiteProducts",
          id: `${studyId}${studySiteId}`,
          payload: newStudySiteProduct,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateStudySiteProduct: async (
    { commit },
    { studyId, studySiteId, payload }
  ) => {
    try {
      const updatedStudySiteProduct = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedStudySiteProduct)
        commit("UPDATE_CHILD_ARRAY", {
          property: "studySiteProducts",
          propertyTwo: `${studyId}${studySiteId}`,
          idProp: "minervaId",
          payload: updatedStudySiteProduct,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteStudySiteProduct: async (
    { commit },
    { studyId, studySiteId, payload }
  ) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "studySiteProducts",
          id: `${studyId}${studySiteId}`,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySiteProductNetworks: async (
    { commit },
    { studyId, studySiteId, productId }
  ) => {
    try {
      const studySiteProductNetworks = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct/${productId}/studysiteproductnetwork`,
        })
      );

      if (studySiteProductNetworks)
        commit("SET_ARRAY_BY_ID", {
          property: "studySiteProductNetworks",
          id: `${studyId}${studySiteId}${productId}`,
          payload: studySiteProductNetworks,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addStudySiteProductNetwork: async (
    { commit },
    { studyId, studySiteId, productId, payload }
  ) => {
    try {
      const newStudySiteProductNetwork = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct/${productId}/studysiteproductnetwork`,
        }),
        payload
      );
      if (newStudySiteProductNetwork)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "studySiteProductNetworks",
          id: `${studyId}${studySiteId}${productId}`,
          payload: newStudySiteProductNetwork,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateStudySiteProductNetwork: async (
    { commit },
    { studyId, studySiteId, productId, productNetworkId, payload }
  ) => {
    try {
      const updatedStudySiteProductNetwork = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct/${productId}/studysiteproductnetwork/${productNetworkId}`,
        }),
        payload
      );
      if (updatedStudySiteProductNetwork)
        commit("UPDATE_CHILD_ARRAY", {
          property: "studySiteProductNetworks",
          propertyTwo: `${studyId}${studySiteId}${productId}`,
          idProp: "minervaId",
          payload: updatedStudySiteProductNetwork,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteStudySiteProductNetwork: async (
    { commit },
    { studyId, studySiteId, productId, payload }
  ) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct/${productId}/studysiteproductnetwork/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "studySiteProductNetworks",
          id: `${studyId}${studySiteId}${productId}`,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySiteProductNetworkPrice: async (
    { commit },
    { studyId, studySiteId, productId, networkId }
  ) => {
    try {
      const studySiteProductNetworkPrices = await apiWithThrottling.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct/${productId}/studysiteproductnetwork/${networkId}/studysiteproductnetworkprice`,
        })
      );

      if (studySiteProductNetworkPrices)
        commit("SET_ARRAY_BY_ID", {
          property: "studySiteProductNetworkPrices",
          id: `${studyId}${studySiteId}${productId}${networkId}`,
          payload: studySiteProductNetworkPrices,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateStudySiteProductNetworkPrice: async (
    { dispatch },
    { studyId, studySiteId, productId, networkId, payload }
  ) => {
    try {
      const updatedStudySiteProductNetworkPrice = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct/${productId}/studysiteproductnetwork/${networkId}/studysiteproductnetworkprice`,
        }),
        payload
      );
      if (updatedStudySiteProductNetworkPrice)
        dispatch("fetchStudySiteProductNetworkPrice", {
          studyId,
          studySiteId,
          productId,
          networkId,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudySiteProductNetworkFreight: async (
    { commit },
    { studyId, studySiteId, productId, networkId }
  ) => {
    try {
      const studySiteProductNetworkFreight = await apiWithThrottling.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct/${productId}/studysiteproductnetwork/${networkId}/studysiteproductnetworkfreight`,
        })
      );
      if (studySiteProductNetworkFreight)
        commit("SET_ARRAY_BY_ID", {
          property: "studySiteProductNetworkFreight",
          id: `${studyId}${studySiteId}${productId}${networkId}`,
          payload: studySiteProductNetworkFreight,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateStudySiteProductNetworkFreight: async (
    { dispatch },
    { studyId, studySiteId, productId, networkId, payload }
  ) => {
    try {
      const updatedStudySiteProductNetworkFreight = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/studysiteproduct/${productId}/studysiteproductnetwork/${networkId}/studysiteproductnetworkfreight`,
        }),
        payload
      );
      if (updatedStudySiteProductNetworkFreight)
        dispatch("fetchStudySiteProductNetworkFreight", {
          studyId,
          studySiteId,
          productId,
          networkId,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
