import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  orderingAdmins: {},
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchOrderingAdmins: async ({ commit }, { studyId }) => {
    try {
      const orderingAdmins = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studydoseorderadmin`,
        })
      );

      if (orderingAdmins)
        commit("SET_ARRAY_BY_ID", {
          property: "orderingAdmins",
          id: studyId,
          payload: orderingAdmins,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addOrderingAdmin: async ({ commit }, { studyId, payload }) => {
    try {
      const newAdmin = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studydoseorderadmin`,
        }),
        payload
      );
      if (newAdmin)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "orderingAdmins",
          id: studyId,
          payload: newAdmin,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteOrderingAdmin: async ({ commit }, { studyId, payload }) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${studyId}/studydoseorderadmin/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "orderingAdmins",
          id: studyId,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
