import { reactive, computed, watchEffect } from "vue";
import { AbilityBuilder, Ability } from "@casl/ability";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";
import { ToastService } from "@/toast/toast";

const baseActions = ["list", "create", "get", "patch", "delete"];

const state = reactive({
  roles: {},
  rolePermissions: {},
  allPermissions: {},
  abilities: {},
  systemAbility: {},
  loading: false,
});

// set all roles by display_name
const setRoles = (roles) => {
  for (const role of roles) {
    state.roles[role.display_name] = role;
  }
};

// set all role permissions by role_name
const setRolePermissions = (rolePermissions) => {
  for (const rolePermission of rolePermissions) {
    if (state.rolePermissions[rolePermission.role_name]) {
      if (
        !state.rolePermissions[rolePermission.role_name].find(
          (role) => role.minervaId === rolePermission.minervaId
        )
      )
        state.rolePermissions[rolePermission.role_name].push(rolePermission);
    } else {
      state.rolePermissions[rolePermission.role_name] = [rolePermission];
    }
  }
};

// set all permissions by name
const setAllPermissions = (permissions) => {
  for (const permission of permissions) {
    state.allPermissions[permission.name] = permission;
  }
};

const fetchRoles = async () => {
  try {
    const roles = await axiosInstance.get(
      buildBaseUrl({
        apiModule: "userManagerApi",
        url: "role?limit=1000",
      }),
      false
    );
    if (roles) setRoles(roles);
  } catch (error) {
    ToastService.addWithNotification({
      type: "error",
      message: `There was an error fetching permissions: ${error}. Please refresh the page.`,
    });
  }
};
const fetchRolePermissions = async () => {
  try {
    const rolePermissions = await axiosInstance.get(
      buildBaseUrl({
        apiModule: "userManagerApi",
        url: "rolepermission?limit=1000",
      }),
      false
    );
    if (rolePermissions) setRolePermissions(rolePermissions);
  } catch (error) {
    ToastService.addWithNotification({
      type: "error",
      message: `There was an error fetching permissions: ${error}. Please refresh the page.`,
    });
  }
};

const fetchAllPermissions = async () => {
  try {
    const permissions = await axiosInstance.get(
      buildBaseUrl({
        apiModule: "userManagerApi",
        url: "permission?limit=1000",
      }),
      false
    );
    if (permissions) setAllPermissions(permissions);
  } catch (error) {
    ToastService.addWithNotification({
      type: "error",
      message: `There was an error fetching permissions: ${error}. Please refresh the page.`,
    });
  }
};

const defineAbility = (rolePermissions) => {
  const { can, cannot, build } = new AbilityBuilder(Ability);

  // map all endpoints of the role to an array of provided actions
  const permissionsMap = rolePermissions.reduce((res, permission) => {
    const permissionDetails = permission.permission_name.split(".");
    const endpoint = permissionDetails[1];
    const allowedAction = permissionDetails[2];

    res[endpoint]
      ? res[endpoint].push(allowedAction)
      : (res[endpoint] = [allowedAction]);

    return res;
  }, {});

  // loop through the array of each of the actions for each role, if it has that action it can do it, if not it cannot.
  for (const endpoint in permissionsMap) {
    baseActions.forEach((action) => {
      permissionsMap[endpoint].includes(action)
        ? can(`${action}`, `${endpoint}`)
        : cannot(`${action}`, `${endpoint}`);
    });
  }

  return build();
};

const addSystemAbility = (system, role) => {
  if (!Object.prototype.hasOwnProperty.call(state.systemAbility, system))
    state.systemAbility[system] = defineAbility(state.rolePermissions[role]);
};

const getSystemAbility = (system) => {
  return state.systemAbility[system];
};

// create and store user abilities by the system
const createUserAbilities = (user) => {
  for (const system in user["https://minerva.cereb.io/custom_claims"].systems) {
    const systemRole =
      user["https://minerva.cereb.io/custom_claims"].systems[system];

    if (systemRole.role === "SuperAdmin") {
      state.systemAbility[systemRole.key] = "SuperAdmin";
    } else {
      addSystemAbility(systemRole.key, systemRole.role);
    }
  }
};

// check if laoding, if not check the users ability for the system, if yes set a watcher to wait until loading is false
const checkUserAbility = (system, action, endpoint) => {
  const { loading } = permissionsPlugin;

  const checkAbility = () => {
    const systemAbility = getSystemAbility(system);
    return systemAbility === "SuperAdmin"
      ? true
      : systemAbility.can(action, endpoint);
  };

  if (!loading.value) {
    return checkAbility();
  }

  watchEffect(() => {
    if (!loading.value) {
      return checkAbility();
    }
  });
};

// set loading, fetch all permissions, create the abilities per system for the user, set loading false
const buildPermissions = async (user) => {
  state.loading = true;
  await Promise.all([
    fetchRoles(),
    fetchRolePermissions(),
    fetchAllPermissions(),
  ]);
  createUserAbilities(user);
  state.loading = false;
};

const permissionsPlugin = {
  loading: computed(() => state.loading),
  buildPermissions,
};

export default {
  install: (app) => {
    app.provide("Permissions", permissionsPlugin);
    app.config.globalProperties.$checkUserAbility = (
      system,
      action,
      endpoint
    ) => {
      return checkUserAbility(system, action, endpoint);
    };
  },
};

// to use the permissions plugin it will need to be installed using appInstance.use(Permissions) in your main.js file. This will make the plugin available to be injected to App.vue for initialization functions and the global function $checkUserAbility will be available to all templates.

// Init the plugin in the App.vue setup function like so, note you will need access to the user object:
// const auth = inject("Auth");
// const permissions = inject("Permissions");
// if (auth.user.value) {
//   (async () => {
//     await permissions.buildPermissions(auth.user.value);
//   })();
// }

// In your templates you can now use $checkUserAbility(system, action, endpoint) where system is the system we are accessing, action is the action we wish to perform (get, list, create, patch, delete), and endpoint is the matching endpoint name from the permission.

/* <button v-if="$checkUserAbility('pharmacy_order', 'create', 'minervaapi')">
Create API Reader
</button>
<button v-if="$checkUserAbility('pharmacy_order', 'delete', 'minervaapi')">
Delete API Reader
</button>
<button v-if="$checkUserAbility('pharmacy_order', 'get', 'minervaapi')">
Get API Reader
</button> */
