import { ref } from "vue";
import { ToastService } from "@/toast/toast";

class ApiResource {
  constructor(resource, request, successMsg, errorMsg) {
    if (typeof resource !== "function" && typeof resource !== "undefined")
      throw new Error("Resource needs to be a function or undefined");
    if (typeof request !== "function")
      throw new Error("Request must be a function");

    this.resource = resource;
    this.request = request;
    this.loading = ref(false);
    this.successMsg = successMsg;
    this.errorMsg = errorMsg;
  }

  getData(param) {
    return typeof this.resource === "function"
      ? this.resource(param)
      : this.resource;
  }

  getLoading() {
    return this.loading.value;
  }

  async fetchResource(param) {
    this.loading.value = true;

    try {
      const response = await this.request(param);

      if (this.successMsg) {
        ToastService.addWithNotification({
          type: "success",
          message: this.successMsg,
        });
      }

      return response;
    } catch (error) {
      if (this.errorMsg) {
        ToastService.addWithNotification({
          type: "error",
          message: this.errorMsg,
          moreInfo: error,
        });
      }
      return Promise.reject(error);
    } finally {
      this.loading.value = false;
    }
  }
}

export default {
  install: (app) => {
    app.provide("ApiResource", ApiResource);
  },
};
