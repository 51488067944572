import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  studies: [],
  studyAdministrators: [],
  orderEmailReceivers: [],
  sites: [],
  networks: [],
  allSites: [],
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchStudies: async ({ commit }, offset = 0) => {
    try {
      const studies = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study?offset=${offset}&limit=30`,
        })
      );
      if (studies)
        commit("ADD_TO_ARRAY", {
          property: "studies",
          payload: studies,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  refreshStudies: async ({ commit }, length) => {
    if (!length) {
      throw new Error("length is required");
    }
    try {
      const studies = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study?&limit=${length}`,
        })
      );
      if (studies)
        commit("SET", {
          property: "studies",
          payload: studies,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createStudy: async ({ commit }, payload) => {
    try {
      const newStudy = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: "study",
        }),
        payload
      );
      if (newStudy)
        commit("ADD_TO_ARRAY", {
          property: "studies",
          payload: newStudy,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateStudy: async ({ commit }, payload) => {
    try {
      const updatedStudy = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedStudy)
        commit("UPDATE_ARRAY", {
          property: "studies",
          idProp: "minervaId",
          payload: updatedStudy,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteStudy: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `study/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "studies",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchStudyAdministrators: async ({ commit }) => {
    try {
      const studies = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: "studysystemadmin",
        })
      );
      if (studies)
        commit("SET", {
          property: "studyAdministrators",
          payload: studies,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addStudyAdmin: async ({ commit }, payload) => {
    try {
      const newAdmin = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: "studysystemadmin",
        }),
        payload
      );
      if (newAdmin)
        commit("ADD_TO_ARRAY", {
          property: "studyAdministrators",
          payload: newAdmin,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteStudyAdmin: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `studysystemadmin/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "studyAdministrators",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchOrderEmailReceivers: async ({ commit }) => {
    try {
      const orderEmailReceivers = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: "doseorderemailreceiver",
        })
      );
      if (orderEmailReceivers)
        commit("SET", {
          property: "orderEmailReceivers",
          payload: orderEmailReceivers,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addOrderEmailReceiver: async ({ commit }, payload) => {
    try {
      const newEmailReceiver = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: "doseorderemailreceiver",
        }),
        payload
      );
      if (newEmailReceiver)
        commit("ADD_TO_ARRAY", {
          property: "orderEmailReceivers",
          payload: newEmailReceiver,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteOrderEmailReceiver: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "studyManagerApi",
          url: `doseorderemailreceiver/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "orderEmailReceivers",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  refreshSites: async ({ commit }, length) => {
    if (!length) {
      throw new Error("length is required");
    }
    try {
      const sites = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "siteManagerApi",
          url: `site?limit=${length}&orderBy=name`,
        })
      );
      if (sites)
        commit("SET", {
          property: "sites",
          payload: sites,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchAllSites: async ({ commit }) => {
    try {
      const allSites = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "siteManagerApi",
          url: `site?limit=1000&orderBy=name`,
        })
      );
      if (allSites)
        commit("ADD_TO_ARRAY", {
          property: "allSites",
          payload: allSites,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchSites: async ({ commit }, offset = 0) => {
    try {
      const sites = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "siteManagerApi",
          url: `site?offset=${offset}&limit=20&orderBy=name`,
        })
      );
      if (sites)
        commit("ADD_TO_ARRAY", {
          property: "sites",
          payload: sites,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchSite: async (_, siteId) => {
    try {
      const site = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "siteManagerApi",
          url: `site/${siteId}`,
        })
      );
      return site;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createSite: async ({ commit }, payload) => {
    try {
      const newSite = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "siteManagerApi",
          url: "site",
        }),
        payload
      );
      if (newSite)
        commit("ADD_TO_ARRAY", {
          property: "sites",
          payload: newSite,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateSite: async ({ commit }, payload) => {
    try {
      const updatedSite = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "siteManagerApi",
          url: `site/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedSite)
        commit("UPDATE_ARRAY", {
          property: "sites",
          idProp: "minervaId",
          payload: updatedSite,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteSite: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "siteManagerApi",
          url: `site/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "sites",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchNetworks: async ({ commit }) => {
    try {
      const networks = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "doseNetworkManagerApi",
          url: "dosenetwork?orderBy=name",
        })
      );
      if (networks)
        commit("SET", {
          property: "networks",
          payload: networks,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createNetwork: async ({ commit }, payload) => {
    try {
      const newNetwork = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "doseNetworkManagerApi",
          url: "dosenetwork",
        }),
        payload
      );
      if (newNetwork)
        commit("ADD_TO_ARRAY", {
          property: "networks",
          payload: newNetwork,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateNetwork: async ({ commit }, payload) => {
    try {
      const updatedNetwork = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "doseNetworkManagerApi",
          url: `dosenetwork/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedNetwork)
        commit("UPDATE_ARRAY", {
          property: "networks",
          idProp: "minervaId",
          payload: updatedNetwork,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteNetwork: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "doseNetworkManagerApi",
          url: `dosenetwork/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "networks",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
