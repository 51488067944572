import apiDict from "@/api/apiDict";

// apiModule is fed to the api dictionary for the base endpoint
// parameters should be an object with a urlParameters property, an array of objects with the properties entity and id. We map over these to generate the /entity/id strings for the URL. If one entity does not have an id we check for that and do not append the empty /.
// If the parameters object has the query property we append it with a ?.

export const buildBaseUrl = (parameters) => {
  return `${parameters.apiModule ? apiDict[parameters.apiModule] : ""}/${
    parameters.url
  }`;
};
