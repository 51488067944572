<template>
  <div class="layout-menu-container">
    <AppSubmenu :items="model" class="layout-menu" :root="true" />
  </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";

export default {
  components: {
    AppSubmenu: AppSubmenu,
  },
  props: {
    model: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
