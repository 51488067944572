<template>
  <div class="page-loader">
    <ProgressSpinner />
  </div>
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";

export default {
  name: "AppLoading",
  components: {
    ProgressSpinner,
  },
};
</script>

<style lang="scss">
.page-loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
