import axios from "axios";
import Auth from "@/auth";

const MAX_REQUESTS_COUNT = 5;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

// create new axios instance
const apiWithThrottling = axios.create({});
apiWithThrottling.defaults.headers.common["Accept"] = "application/json";
apiWithThrottling.defaults.headers.common["X-Requested-With"] =
  "XMLHttpRequest";
apiWithThrottling.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";
/**
 * Axios Request Interceptor
 */
apiWithThrottling.interceptors.request.use(async (config) => {
  const token = await Auth.getTokenSilently();
  config.headers.Authorization = `Bearer ${token}`;
  return new Promise((resolve) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});

/**
 * Axios Response Interceptor
 */
apiWithThrottling.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response.data.data);
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    if (error.response.status && error.response.status === 403) {
      return Promise.reject("You are unauthorized to perform this action.");
    }
    const err = error?.response?.data?.error || false;

    if (err) {
      // sending full message for now and showing it in a details button
      // const properties = err.split("\n");
      // const errs = [];
      // properties.forEach((property) => {
      //   const tup = property.split("Error:");
      //   errs.push(tup[1]);
      // });
      // return Promise.reject(errs.join(", "));
      return Promise.reject(err);
    }
  }
);

export default apiWithThrottling;
