import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  pharmacy: {},
  pharmacies: [],
  pharmacyTechnicians: {},
  pharmacySystemAdmins: [],
  pharmacyMaps: [],
  pharmacyAvailableDates: {},
  pharmacyDocuments: {},
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchPharmacy: async ({ commit }, pharmacyId) => {
    try {
      const pharmacy = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${pharmacyId}`,
        })
      );
      if (pharmacy)
        commit("SET", {
          property: "pharmacy",
          payload: pharmacy,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchPharmacies: async ({ commit }) => {
    try {
      const pharmacies = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: "pharmacy?limit=1000&orderBy=name",
        })
      );
      if (pharmacies)
        commit("SET", {
          property: "pharmacies",
          payload: pharmacies,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createPharmacy: async ({ commit }, payload) => {
    try {
      const newPharmacy = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: "pharmacy",
        }),
        payload
      );
      if (newPharmacy)
        commit("ADD_TO_ARRAY", {
          property: "pharmacies",
          payload: newPharmacy,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updatePharmacy: async ({ commit }, payload) => {
    try {
      const updatedPharmacy = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedPharmacy)
        commit("UPDATE_ARRAY", {
          property: "pharmacies",
          idProp: "minervaId",
          payload: updatedPharmacy,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deletePharmacy: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "pharmacies",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchPharmacyTechnicians: async ({ commit }, { pharmacyId }) => {
    try {
      const pharmacyTechnicians = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${pharmacyId}/pharmacytechnician`,
        })
      );

      if (pharmacyTechnicians)
        commit("SET_ARRAY_BY_ID", {
          property: "pharmacyTechnicians",
          id: pharmacyId,
          payload: pharmacyTechnicians,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createPharmacyTechnician: async ({ commit }, { pharmacyId, payload }) => {
    try {
      const newPharmacyTechnician = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${pharmacyId}/pharmacytechnician`,
        }),
        payload
      );
      if (newPharmacyTechnician)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "pharmacyTechnicians",
          id: pharmacyId,
          payload: newPharmacyTechnician,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updatePharmacyTechnician: async ({ commit }, { pharmacyId, payload }) => {
    try {
      const updatedPharmacyTechnician = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${pharmacyId}/pharmacytechnician/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedPharmacyTechnician)
        commit("UPDATE_CHILD_ARRAY", {
          property: "pharmacyTechnicians",
          propertyTwo: pharmacyId,
          idProp: "minervaId",
          payload: updatedPharmacyTechnician,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deletePharmacyTechnician: async ({ commit }, { pharmacyId, payload }) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${pharmacyId}/pharmacytechnician/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "pharmacyTechnicians",
          id: pharmacyId,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchPharmacySystemAdmins: async ({ commit }) => {
    try {
      const pharmacySystemAdmins = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: "pharmacysystemadmin",
        })
      );
      if (pharmacySystemAdmins)
        commit("SET", {
          property: "pharmacySystemAdmins",
          payload: pharmacySystemAdmins,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createPharmacySystemAdmin: async ({ commit }, payload) => {
    try {
      const newPharmacySystemAdmin = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: "pharmacysystemadmin",
        }),
        payload
      );
      if (newPharmacySystemAdmin)
        commit("ADD_TO_ARRAY", {
          property: "pharmacySystemAdmins",
          payload: newPharmacySystemAdmin,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deletePharmacySystemAdmin: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacysystemadmin/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "pharmacySystemAdmins",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchPharmacyMaps: async ({ commit }) => {
    try {
      const pharmacyMaps = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyMapperApi",
          url: "pharmacymap",
        })
      );
      if (pharmacyMaps)
        commit("SET", {
          property: "pharmacyMaps",
          payload: pharmacyMaps,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createPharmacyMap: async ({ commit }, payload) => {
    try {
      const newPharmacyMap = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "pharmacyMapperApi",
          url: "pharmacymap",
        }),
        payload
      );
      if (newPharmacyMap)
        commit("ADD_TO_ARRAY", {
          property: "pharmacyMaps",
          payload: newPharmacyMap,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updatePharmacyMap: async ({ commit }, payload) => {
    try {
      const updatedPharmacyMap = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "pharmacyMapperApi",
          url: `pharmacymap/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedPharmacyMap)
        commit("UPDATE_ARRAY", {
          property: "pharmacyMaps",
          idProp: "minervaId",
          payload: updatedPharmacyMap,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deletePharmacyMap: async ({ commit }, payload) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "pharmacyMapperApi",
          url: `pharmacymap/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY", {
          property: "pharmacyMaps",
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchPharmacyAvailableDates: async (
    { commit },
    { pharmacyId, searchDate }
  ) => {
    try {
      const pharmacyAvailableDates = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${pharmacyId}/pharmacyproductiondate?start_date=${searchDate}`,
        })
      );
      if (pharmacyAvailableDates)
        commit("SET_ARRAY_BY_ID", {
          property: "pharmacyAvailableDates",
          id: pharmacyId,
          payload: pharmacyAvailableDates,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createPharmacyAvailableDate: async ({ commit }, { pharmacyId, payload }) => {
    try {
      const newPharmacyAvailableDate = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${pharmacyId}/pharmacyproductiondate`,
        }),
        payload
      );
      if (newPharmacyAvailableDate)
        commit("ADD_TO_ARRAY_BY_ID", {
          property: "pharmacyAvailableDates",
          id: pharmacyId,
          payload: newPharmacyAvailableDate,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updatePharmacyAvailableDate: async ({ commit }, { pharmacyId, payload }) => {
    try {
      const updatedPharmacyAvailableDate = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${pharmacyId}/pharmacyproductiondate/${payload.minervaId}`,
        }),
        payload
      );
      if (updatedPharmacyAvailableDate)
        commit("UPDATE_CHILD_ARRAY", {
          property: "pharmacyAvailableDates",
          propertyTwo: pharmacyId,
          idProp: "minervaId",
          payload: updatedPharmacyAvailableDate,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deletePharmacyAvailableDate: async ({ commit }, { pharmacyId, payload }) => {
    try {
      const success = await axiosInstance.delete(
        buildBaseUrl({
          apiModule: "pharmacyInfoApi",
          url: `pharmacy/${pharmacyId}/pharmacyproductiondate/${payload.minervaId}`,
        })
      );
      if (success)
        commit("DELETE_FROM_ARRAY_BY_ID", {
          property: "pharmacyAvailableDates",
          id: pharmacyId,
          idProp: "minervaId",
          payload,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchPharmacyDocuments: async ({ commit }, { pharmacyId, docType }) => {
    try {
      const pharmacyDocuments = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "pharmacyManagerApi",
          url: `pharmacy/${pharmacyId}/pharmacy_document?doc_type=${docType}`,
        })
      );

      if (pharmacyDocuments)
        commit("SET_ARRAY_BY_ID", {
          property: "pharmacyDocuments",
          id: `${pharmacyId}-${docType}`,
          payload: pharmacyDocuments,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
