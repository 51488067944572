const state = () => ({
  openLeftSidebar: true,
  openRightSidebar: false,
});

const mutations = {
  toggleOpenLeftSidebar: (state) => {
    state.openLeftSidebar = !state.openLeftSidebar;
  },
  toggleOpenRightSidebar: (state) => {
    state.openRightSidebar = !state.openRightSidebar;
  },
};

const actions = {
  toggleLeftSidebar: ({ commit }) => {
    commit("toggleOpenLeftSidebar");
  },
  toggleRightSidebar: ({ commit }) => {
    commit("toggleOpenRightSidebar");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
