export default {
  SET: (state, { property, payload }) => {
    checkStateExists(state, property, payload);
    state[property] = payload;
  },
  SET_OBJECT_BY_IDPROP: (state, { property, idProp, payload }) => {
    checkStateExists(state, property, payload);
    checkStateExists(payload, idProp, payload);
    state[property][payload[idProp]] = payload;
  },
  SET_ARRAY_BY_ID: (state, { property, id, payload }) => {
    checkStateExists(state, property, payload);
    state[property][id] = payload;
  },
  SET_TO_CHILD_OBJECT: (state, { property, propertyTwo, payload }) => {
    checkStateExists(state, property, propertyTwo);
    checkStateExists(state[property], propertyTwo, payload);
    state[property][propertyTwo] = payload;
  },
  ADD_TO_ARRAY: (state, { property, payload }) => {
    checkStateExists(state, property, payload);
    if (Array.isArray(payload)) {
      state[property].push(...payload);
    } else {
      state[property].push(payload);
    }
  },
  ADD_TO_ARRAY_BY_ID: (state, { property, id, payload }) => {
    checkStateExists(state, property, payload);
    if (!state[property][id]) {
      state[property][id] = [];
    }
    if (Array.isArray(payload)) {
      state[property][id].push(...payload);
    } else {
      state[property][id].push(payload);
    }
  },
  ADD_TO_CHILD_ARRAY: (state, { property, propertyTwo, payload }) => {
    checkStateExists(state, property, payload);
    checkStateExists(state[property], propertyTwo, payload);
    if (Array.isArray(payload)) {
      state[property][propertyTwo].push(...payload);
    } else {
      state[property][propertyTwo].push(payload);
    }
  },
  UPDATE_ARRAY: (state, { property, idProp, payload }) => {
    checkStateExists(state, property, payload);
    checkStateExists(payload, idProp, payload);
    const itemIndex = getIndex(state, { property, idProp, payload });
    if (itemIndex === -1) {
      throw `Cannot update ${payload}. Not found in state.${property}`;
    }
    state[property].splice(itemIndex, 1, payload);
  },
  UPDATE_CHILD_ARRAY: (state, { property, propertyTwo, idProp, payload }) => {
    checkStateExists(state, property, payload);
    checkStateExists(payload, idProp, payload);
    checkStateExists(state[property], propertyTwo, payload);
    const itemIndex = getIndex(state[property], {
      property: propertyTwo,
      idProp,
      payload,
    });
    if (itemIndex === -1) {
      throw `Cannot update ${payload}. Not found in state.${propertyTwo}`;
    }
    state[property][propertyTwo].splice(itemIndex, 1, payload);
  },
  MERGE_ITEM_IN_CHILD_ARRAY: (
    state,
    { property, propertyTwo, idProp, payload }
  ) => {
    checkStateExists(state, property, payload);
    checkStateExists(payload, idProp, payload);
    checkStateExists(state[property], propertyTwo, payload);
    const itemIndex = getIndex(state[property], {
      property: propertyTwo,
      idProp,
      payload,
    });
    if (itemIndex === -1) {
      throw `Cannot update ${payload}. Not found in state.${propertyTwo}`;
    }
    const originalItem = state[property][propertyTwo][itemIndex];
    const newItem = {
      ...originalItem,
      ...payload,
    };
    state[property][propertyTwo].splice(itemIndex, 1, newItem);
  },
  DELETE_FROM_ARRAY: (state, { property, idProp, payload }) => {
    checkStateExists(state, property, payload);
    checkStateExists(payload, idProp, payload);
    const itemIndex = getIndex(state, { property, idProp, payload });
    if (itemIndex === -1) {
      throw `Cannot delete ${payload}. Not found in state.${property}`;
    }

    state[property].splice(itemIndex, 1);
  },
  DELETE_FROM_ARRAY_BY_ID: (state, { property, id, idProp, payload }) => {
    checkStateExists(state, property, payload);
    checkStateExists(payload, idProp, payload);
    const itemIndex = getIndex(state[property], {
      property: id,
      idProp,
      payload,
    });
    if (itemIndex === -1) {
      throw `Cannot delete ${payload}. Not found in state.${property}`;
    }

    state[property][id].splice(itemIndex, 1);
  },
  REPLACE_STATE: (state, { property, newEntity }) => {
    checkStateExists(state, property, property);
    state[property] = newEntity;
  },
};

function checkStateExists(object, property, item) {
  if (!Object.prototype.hasOwnProperty.call(object, property)) {
    throw `Cannot modify ${item}, ${object}.${property} does not exist`;
  }
}

function getIndex(state, { property, idProp, payload }) {
  return state[property].findIndex((item) => item[idProp] === payload[idProp]);
}
