import ToastEventBus from "primevue/components/toast/ToastEventBus";
import { PrimeVueToastSymbol } from "primevue/usetoast";
import store from "@/store";

const toastTypes = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
  WARN: "warn",
};

const toasts = {
  [toastTypes.ERROR]: (message, moreInfo) => {
    store.dispatch("notifications/addNotification", {
      severity: "error",
      summary: "Error",
      detail: message,
      moreInfo: moreInfo,
    });

    ToastService.add({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  },
  [toastTypes.INFO]: (message, moreInfo) => {
    store.dispatch("notifications/addNotification", {
      severity: "info",
      summary: "Info",
      detail: message,
      moreInfo: moreInfo,
    });

    ToastService.add({
      severity: "info",
      summary: "Info",
      detail: message,
      life: 3000,
    });
  },
  [toastTypes.SUCCESS]: (message, moreInfo) => {
    store.dispatch("notifications/addNotification", {
      severity: "success",
      summary: "Success",
      detail: message,
      moreInfo: moreInfo,
    });

    ToastService.add({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  },
  [toastTypes.WARN]: (message, moreInfo) => {
    store.dispatch("notifications/addNotification", {
      severity: "warn",
      summary: "Warning",
      detail: message,
      moreInfo: moreInfo,
    });

    ToastService.add({
      severity: "warn",
      summary: "Warning",
      detail: message,
      life: 3000,
    });
  },
};

export const ToastService = {
  addWithNotification: ({ type, message, moreInfo }) => {
    if (!Object.values(toastTypes).includes(type)) {
      throw new Error("invalid toast type");
    }
    toasts[type](message, moreInfo);
  },
  add: (toast) => {
    ToastEventBus.emit("add", toast);
  },
  removeGroup: (group) => {
    ToastEventBus.emit("remove-group", group);
  },
  removeAllGroups: () => {
    ToastEventBus.emit("remove-all-groups");
  },
};
export default {
  install: (app) => {
    app.config.globalProperties.$toast = ToastService;
    app.provide(PrimeVueToastSymbol, ToastService);
  },
};
