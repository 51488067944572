import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import Auth from "./auth";
import Permissions from "./permissions";
import ApiResource from "@/api/apiResource";
import Toast from "@/toast/toast";
import VuelidatePlugin from "@vuelidate/core";

// primevue components and plugins
import PrimeVue from "primevue/config";
import Ripple from "primevue/ripple";
import Tooltip from "primevue/tooltip";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import ConfirmationService from "primevue/confirmationservice";

// PrimeVue theme
import "@/assets/theme.css";

// PrimeVue core CSS and icons
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./assets/layout/layout.scss";

async function init() {
  const AuthPlugin = await Auth.init({
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    },
  });

  createApp(App)
    .use(router)
    .use(store)
    .use(PrimeVue)
    .use(ConfirmationService)
    .use(Toast)
    .use(AuthPlugin)
    .use(Permissions)
    .use(ApiResource)
    .use(VuelidatePlugin)
    .component("Button", Button)
    .component("InputText", InputText)
    .directive("ripple", Ripple)
    .directive("tooltip", Tooltip)
    .mount("#app");
}

init();
