import vuexMutations from "@/helpers/vuexMutationsHelpers";
import axiosInstance from "@/api/apiConfig.js";
import { buildBaseUrl } from "@/api/apiUrlBuilder";

const state = () => ({
  orderRequests: {},
});

const mutations = {
  ...vuexMutations,
};

const actions = {
  fetchOrderRequests: async ({ commit }, { studyId, studySiteId }) => {
    try {
      const orderRequests = await axiosInstance.get(
        buildBaseUrl({
          apiModule: "doseOrderManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/doseorderrequest?orderBy=-created_at`,
        })
      );
      if (orderRequests)
        commit("SET_ARRAY_BY_ID", {
          property: "orderRequests",
          id: studySiteId,
          payload: orderRequests,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createOrderRequest: async (
    { dispatch },
    { studyId, studySiteId, payload }
  ) => {
    try {
      const newOrderRequest = await axiosInstance.post(
        buildBaseUrl({
          apiModule: "doseOrderManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/doseorderrequest`,
        }),
        payload
      );
      if (newOrderRequest)
        dispatch("fetchOrderRequests", {
          studyId,
          studySiteId,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateOrderRequestStatus: async (
    { commit },
    { studyId, studySiteId, payload }
  ) => {
    try {
      const updatedOrderRequest = await axiosInstance.patch(
        buildBaseUrl({
          apiModule: "doseOrderManagerApi",
          url: `study/${studyId}/studysite/${studySiteId}/doseorderrequest/${payload.minervaId}`,
        }),
        payload
      );

      if (updatedOrderRequest)
        commit("UPDATE_CHILD_ARRAY", {
          property: "orderRequests",
          propertyTwo: studySiteId,
          idProp: "minervaId",
          payload: updatedOrderRequest,
        });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
